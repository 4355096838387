(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("sortablejs"), require("resize-observer-polyfill"), require("intersection-observer"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "sortablejs", "resize-observer-polyfill", "intersection-observer"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsForex"] = factory(require("mwc-markets-core"), require("sortablejs"), require("resize-observer-polyfill"), require("intersection-observer"));
	else
		root["mwcMarketsForex"] = factory(root["mwcMarketsCore"], root["Sortable"], root["ResizeObserver"], root["IntersectionObserver"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__57__, __WEBPACK_EXTERNAL_MODULE__124__, __WEBPACK_EXTERNAL_MODULE__183__) {
return 