<template>
    <section :class="[namespace(), skinStyle]">
        <markets-ui-list
            :skin="skin"
            :data-model="dataList"
            with-icon
            :class="namespace('dragable-container')"
        >
            <markets-ui-button
                slot="mds-list-item-right"
                icon="remove"
                variation="icon-only"
                :skin="skin"
                :text="labels.remove"
                :class="namespace('remove-icon')"
                @click="onRemove"
            ></markets-ui-button>
        </markets-ui-list>
        <footer :class="[namespace('__footer')]">
            <slot name="footer"></slot>
        </footer>
    </section>
</template>
<script>
import Sortable from 'sortablejs';
import utils from '../../utils';
export default {
    name: 'markets-ui-dragable-list',
    props: {
        dataModel: {
            type: Array,
            default() {
                return [];
            }
        },
        skin: {
            type: String,
            default: ''
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        },
        minNumber: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            dataList: []
        };
    },
    created() {
        this.namespace = utils.namespace('ui-dragable-list', false);
    },
    mounted() {
        const dragableEl = this.$el.querySelector(
            `ul.${this.namespace('dragable-container')}`
        );
        this.initSorting(dragableEl);
    },
    computed: {
        skinStyle() {
            const skinSuffix = utils.getSkinSuffix(this.skin);
            return `${this.namespace()}--${skinSuffix} `;
        },
        showRemove() {
            return this.showRemoveIcon && this.dataList.length > this.minNumber;
        }
    },
    watch: {
        dataModel: {
            handler(value) {
                this.$nextTick(() => {
                    this.dataList = this.dataModel.map((item, i) => {
                        const { name, id, metadataBelow } = item;
                        return {
                            name,
                            id: id,
                            metadataBelow: metadataBelow,
                            iconLeft: 'grab-handle',
                            class: [this.namespace('item'), item.class]
                        };
                    });
                });
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        initSorting(el) {
            this.$_sortable = Sortable.create(el, {
                onEnd: this.onDragEnd
            });
        },
        onDragEnd(e) {
            if (e.oldIndex !== e.newIndex) {
                const item = this.dataList.splice(e.oldIndex, 1)[0];
                this.dataList.splice(e.newIndex, 0, item);
                this.$emit('change', this.dataList);
            }
        },
        onRemove(e) {
            const el = utils.closest(e.target, this.namespace('item'));
            if (!el && !el.dataset) {
                return;
            }
            const id = el.dataset.id;
            if (!id) {
                return;
            }
            const index = utils.inArray(this.dataList, item => {
                return item.id === id;
            });
            if (index > -1) {
                this.dataList.splice(index, 1);
                this.$emit('change', this.dataList);
                this.$nextTick(() => {
                    const items = this.$el.querySelectorAll(
                        `.${this.namespace('item')}>span`
                    );
                    if (items && items.length) {
                        if (items[index]) {
                            items[index].focus();
                        } else {
                            items[0].focus();
                        }
                    }
                });
            }
        }
    }
};
</script>
<style lang="scss">
$namespace: 'markets-ui-dragable-list';
@import '@mds/constants';
@import '@mds/typography';

@mixin group-item-font {
    font-family: $mds-typography-font-family-sans-serif;
    font-size: $mds-typography-font-size-s;
}
.#{$namespace} {
    max-height: 240px;
    min-height: 30px;
    overflow: auto;
    width: 100%;
    padding: $mds-space-half-x;
    .mds-list-group__item-text___markets {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .markets-ui-list .mds-list-group__item-metadata-below___markets {
        padding: 0;
    }
    .mds-list-group__item-text___markets {
        width: 170px;
    }
    .markets-ui-list .mds-list-group__item-metadata-below-item___markets {
        font-weight: $mds-typography-font-weight-light;
    }
    .markets-ui-list .mds-list-group__link___markets {
        padding: 4px 0;
    }
    .mds-list-group___markets.mds-list-group--small___markets
        .mds-list-group__left-icon___markets {
        top: 10px;
    }
    &-remove-icon.mds-button___markets.mds-button--icon-only___markets {
        margin-right: $mds-space-three-quarter-x;
    }
    &--dark-gray {
        .#{$namespace} {
            &-grab-icon,
            &-remove-icon {
                fill: $mds-text-color-primary-on-dark;
            }
        }
    }
}
</style>
