import {
    guid,
    getPaddings,
    getMargins,
    outerSizes,
    innerSizes,
    inArray,
    find,
    isArray,
    isFunction,
    isDate,
    isNumeric,
    isObject,
    isEmptyObject,
    isPlainObject,
    extend,
    merge,
    kebabCase,
    debounce,
    throttle,
    removeElement,
    openHyperlink,
    isHidden,
    prefixZero,
    stringFormat,
    cancelablePromise,
    lowerCaseFirstLetter,
    orderBy,
    getValueByPath,
    closest,
    computeTriggerId,
    trim
} from './lang';

import { emptyString, getFormatValue, flash } from './formatter';

import { resizeObserver, visibilityObserver } from './observer';

import loadSVGIcons from './load-icons';

import { getCurrency, getPriceWithCurrency } from './currency';

import { loadJSScript, isJSResponseReceive } from './file-loader';

import { exportToFile, exportForIframe } from './file-export';

import tokenManager from './token-manager';

import keyCode from './key-code';

import {
    Security_Types,
    Autocomplete_Sec_Types,
    Sec_Types,
    getSecurityType
} from './security-types';

function namespace(component, isMWC = true) {
    return (context = '') => {
        if (context && !/^_/.test(context)) {
            context = `-${context}`;
        }
        return `${isMWC ? 'mwc-' : ''}markets-${component}${context}`;
    };
}

function getSkinSuffix(skin) {
    let cls;
    switch (skin) {
        case 'light':
            cls = 'light-gray';
            break;
        case 'dark':
            cls = 'dark-gray';
            break;
        case 'black':
            cls = 'black';
            break;
        default:
            cls = 'white';
            break;
    }
    return cls;
}

function moduleClassName(context) {
    if (typeof context === 'string') {
        context = context.split(' ');
    }
    return context.map(c => `${c}___markets`).join(' ');
}

function getSavedSymbol(security = {}) {
    let symbol;
    // for index, we save instrument.
    if (security.rtType === 10) {
        symbol = security.instrument;
    }
    if (!symbol) {
        symbol =
            security.securityId || security.instrument || security.queryKey;
    }
    return symbol;
}

function getDisplaySymbol(security = {}, withMICCode = true) {
    const symbol =
        security.clientSymbol ||
        security.mstarSymbol ||
        security.rtSymbol ||
        '–';
    let exch = '';
    if (withMICCode && security.MICCode) {
        exch = `${security.MICCode}:`;
    }
    return `${exch}${symbol}`;
}

export default {
    namespace,
    moduleClassName,
    getSkinSuffix,
    guid,
    getPaddings,
    getMargins,
    outerSizes,
    innerSizes,
    inArray,
    findIndex: inArray,
    find,
    isArray,
    isFunction,
    isDate,
    isNumeric,
    isObject,
    isEmptyObject,
    isPlainObject,
    extend,
    merge,
    kebabCase,
    debounce,
    throttle,
    removeElement,
    isHidden,
    emptyString,
    getFormatValue,
    resizeObserver,
    visibilityObserver,
    loadSVGIcons,
    flash,
    loadJSScript,
    isJSResponseReceive,
    exportToFile,
    exportForIframe,
    openHyperlink,
    prefixZero,
    stringFormat,
    cancelablePromise,
    getCurrency,
    getPriceWithCurrency,
    tokenManager,
    lowerCaseFirstLetter,
    orderBy,
    getValueByPath,
    closest,
    keyCode,
    computeTriggerId,
    trim,
    getSavedSymbol,
    getDisplaySymbol,
    Autocomplete_Sec_Types,
    Security_Types,
    Sec_Types,
    getSecurityType
};
