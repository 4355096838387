class TokenManager {
    constructor() {
        this._handler = null;
        this._rtToken = null;
        this._oauth2Token = null;
        this._oauth2TokenExpiresIn = null;
        this._timer = null;
        this._callbacks = [];
    }
    _setRTToken(rtToken) {
        for (let rt = 0; rt < rtToken.length; rt++) {
            if (rtToken[rt].type === 'R') {
                this._rtToken = rtToken[rt].value;
                break;
            }
            if (rtToken[rt].type === 'D') {
                this._rtToken = rtToken[rt].value;
                break;
            }
        }
    }
    setHandler(handler) {
        this._handler = handler;
        return this;
    }
    subscribe(callback) {
        this._callbacks.push(callback);
        if (!this._rtToken || !this._oauth2Token) {
            this._retrieveTokens();
        } else {
            callback({
                realtime: this._rtToken,
                oauth2: this._oauth2Token
            });
        }
    }
    unsubscribe(callback) {
        this._callbacks = this._callbacks.filter(cb => cb !== callback);
    }
    _retrieveTokens(looping) {
        if (!this._pending) {
            this._pending = true;
            this._handler()
                .then(data => {
                    const { rtToken, oauth2 = {} } = data.token;
                    this._setRTToken(rtToken);
                    this._oauth2Token = oauth2.token;
                    this._oauth2TokenExpiresIn = oauth2.expires_in;
                    this._pending = false;
                    this._excuteCallback({
                        realtime: this._rtToken,
                        oauth2: this._oauth2Token
                    });
                    this._scheduleRenewalOAuth2Token();
                })
                .catch(err => {
                    this._pending = false;
                    if (!looping) {
                        this._excuteCallback(err);
                    }
                });
        }
    }
    _scheduleRenewalOAuth2Token() {
        if (this._timer) {
            this._timer = null;
            clearTimeout(this._timer);
        }
        const interval =
            this._oauth2TokenExpiresIn - new Date().getTime() - 5 * 60 * 1000;
        this._timer = setTimeout(() => {
            this._retrieveTokens(true);
        }, interval);
    }
    _excuteCallback(res) {
        this._callbacks.forEach(cb => {
            cb(res);
        });
    }
}

const tokenManager = new TokenManager();
export default tokenManager;
