export default async function loadSVGIcons(
    path = 'https://mwc-cdn.morningstar.com/mc/morningstar-design-system/2.15.0/dist/mds.svg'
) {
    if (document.querySelector('mds-icons')) {
        return;
    }
    const iconTemplate = document.createElement('mds-icons');
    iconTemplate.style.display = 'none';
    document.body.insertBefore(iconTemplate, document.body.childNodes[0]);
    const mdsIconSVGRequest = new XMLHttpRequest();
    mdsIconSVGRequest.open('GET', path, true);
    mdsIconSVGRequest.send();
    mdsIconSVGRequest.onload = () => {
        iconTemplate.innerHTML = mdsIconSVGRequest.responseText;
        if (!document.body) {
            document.addEventListener('DOMContentLoaded', function() {
                return true;
            });
        }
    };
    mdsIconSVGRequest.onerror = () => {
        iconTemplate.parentNode.removeChild(iconTemplate);
    };
}
