import 'intersection-observer';
import ResizeObserver from 'resize-observer-polyfill';
import { guid } from './lang';

class Observer {
    constructor(name) {
        this._callbacks = {};
        const callback = function(entries) {
            for (const entry of entries) {
                const el = entry.target;
                if (el.__observeID__ && this._callbacks[el.__observeID__]) {
                    this._callbacks[el.__observeID__].forEach(cb => {
                        cb(this._getCallbackParam(name, entry));
                    });
                }
            }
        }.bind(this);
        this._obverser =
            name === 'resize'
                ? new ResizeObserver(callback)
                : new window.IntersectionObserver(callback);
    }
    observe(el, callback) {
        if (this._obverser && el) {
            if (!el.__observeID__) {
                el.__observeID__ = guid();
            }
            this._callbacks[el.__observeID__] =
                this._callbacks[el.__observeID__] || [];
            this._callbacks[el.__observeID__].push(callback);
            this._obverser.observe(el);
        }
    }
    unobserve(el, callback) {
        if (
            this._obverser &&
            el &&
            el.__observeID__ &&
            this._callbacks[el.__observeID__]
        ) {
            for (
                let i = 0, l = this._callbacks[el.__observeID__].length, cb;
                i < l;
                i++
            ) {
                cb = this._callbacks[el.__observeID__][i];
                if (cb === callback) {
                    this._callbacks[el.__observeID__].splice(i, 1);
                    break;
                }
            }
            if (this._callbacks[el.__observeID__].length === 0) {
                this._callbacks[el.__observeID__] = null;
                delete this._callbacks[el.__observeID__];
            }
            this._obverser.unobserve(el);
        }
    }
    _getCallbackParam(name, entry) {
        if (name === 'resize') {
            return {
                entry
            };
        } else {
            const oldVisibility = entry.target.__visibility__;
            const visibility =
                entry.isIntersecting && entry.intersectionRatio > 0;
            if (visibility !== oldVisibility) {
                entry.target.__visibility__ = visibility;
                return {
                    visibility
                };
            }
        }
        return {};
    }
}

export const resizeObserver = new Observer('resize');
export const visibilityObserver = new Observer('visibility');
