export const VIEWTYPES = {
    CROSSRATES: 'crossRates',
    TABLE: 'table',
    BOARD: 'board'
};
export const MONITOR = {
    BID: 'BidPrice',
    ASK: 'AskPrice'
};
export const CONTRIBUTOR = {
    COMP: 'COMP',
    FLIT: 'FLIT'
};

export const CRYPTO_CURRENCY_ALIAS = {
    XBT: 'BTC',
    XEH: 'ETH',
    XRP: 'XRP',
    XBH: 'BCH',
    XLM: 'XLM',
    XEO: 'EOS',
    XLT: 'LTC'
};

export function getCurrencyLabel(currency, label) {
    if (currency.indexOf('/') > -1) {
        const [cur1, cur2] = currency.split('/');
        return `${label[cur1]} - ${label[cur2]}`;
    } else {
        return label[currency];
    }
}
export function matchedKeywords(currency, searchKey, label) {
    const toMatch = (
        getCurrencyLabel(currency, label) + currency
    ).toLowerCase();
    searchKey = searchKey.toLowerCase();
    return toMatch.indexOf(searchKey) > -1 ? true : false;
}
export function getCurrencyPairByCurrencies(currencies, split = '') {
    let forex = [];
    currencies.forEach(cur1 => {
        currencies.forEach(cur2 => {
            if (cur1 !== cur2) {
                forex.push(`${cur1}${split}${cur2}`);
            }
        });
    });
    return forex;
}
export function getSymbolByCurrencies(
    veticalCur,
    horizontalCur,
    contributorCode
) {
    let symbolSet = [];
    veticalCur.forEach(cur1 => {
        horizontalCur.forEach(cur2 => {
            // filter same currency, like as: veticalCur：[CAD,JPY] , horizontalCur: [CAD, USD]
            if (cur1 !== cur2) {
                symbolSet.push(getRTSymbol(cur1 + cur2, contributorCode));
            }
        });
    });
    return symbolSet;
}

export function getRTSymbol(symbol, contributorCode) {
    if (contributorCode === CONTRIBUTOR.COMP) {
        return '240.20.' + symbol + contributorCode;
    } else if (contributorCode === CONTRIBUTOR.FLIT) {
        return '245.20.' + symbol + contributorCode;
    } else {
        return symbol + contributorCode;
    }
}
