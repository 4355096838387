var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, attrs: { id: _vm.namespace() } },
    [
      _vm._l(_vm.boardDataModel, function(data, index) {
        return _c(
          "div",
          {
            key: index,
            class: _vm.namespace("item"),
            attrs: { id: _vm.namespace("item-" + data.id) }
          },
          [
            _vm.isCryptocurrency
              ? _c("div", {
                  class: [
                    _vm.namespace("currency-icon"),
                    _vm.namespace("currency--" + data.id.substring(0, 3)),
                    _vm.namespace(
                      _vm.isSmallSize
                        ? "currency-icon-small"
                        : "currency-icon-normal"
                    )
                  ]
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  _vm.namespace("item-currency"),
                  _vm.isSmallSize ? "" : _vm.namespace("item-currency-align")
                ]
              },
              [
                _c("span", [_vm._v(_vm._s(data.name))]),
                _vm._v(" "),
                !_vm.isCryptocurrency
                  ? _c("markets-ui-button", {
                      attrs: {
                        variation: "icon-only",
                        skin: _vm.skin,
                        icon: "triangle-fill-down",
                        text: _vm.labels.addCurrency
                      },
                      on: {
                        click: function($event) {
                          return _vm.showCurrencyPopover($event, data.id)
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { class: _vm.namespace("item-bid") }, [
              _c("div", { class: _vm.namespace("item-price-top") }, [
                _vm._v(
                  "\n                " +
                    _vm._s(data.bidPrice.top) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.namespace("item-price-bottom") }, [
                _c(
                  "span",
                  { class: _vm.namespace("item-price-bottom--large") },
                  [_vm._v(_vm._s(data.bidPrice.bottomL))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { class: _vm.namespace("item-price-bottom--small") },
                  [_vm._v(_vm._s(data.bidPrice.bottomS))]
                )
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.namespace("item-up-down") })
            ]),
            _vm._v(" "),
            !_vm.isSmallSize
              ? _c("div", { class: _vm.namespace("item-spread") }, [
                  _vm._v("\n            " + _vm._s(data.spread) + "\n        ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { class: _vm.namespace("item-ask") }, [
              _c("div", { class: _vm.namespace("item-price-top") }, [
                _vm._v(
                  "\n                " +
                    _vm._s(data.askPrice.top) +
                    "\n            "
                )
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.namespace("item-price-bottom") }, [
                _c(
                  "span",
                  { class: _vm.namespace("item-price-bottom--large") },
                  [_vm._v(_vm._s(data.askPrice.bottomL))]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { class: _vm.namespace("item-price-bottom--small") },
                  [_vm._v(_vm._s(data.askPrice.bottomS))]
                )
              ]),
              _vm._v(" "),
              _c("div", { class: _vm.namespace("item-up-down") })
            ]),
            _vm._v(" "),
            !_vm.isSmallSize
              ? _c("div", { class: _vm.namespace("item-trades") }, [
                  _vm._v("\n            " + _vm._s(data.trades) + "\n        ")
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  _vm.namespace("item-change-p"),
                  _vm.namespace("__" + data.upDown)
                ]
              },
              [_vm._v("\n            " + _vm._s(data.changeP) + "\n        ")]
            ),
            _vm._v(" "),
            _c("div", { class: _vm.namespace("item-time") }, [
              _vm._v(_vm._s(data.time))
            ])
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          class: _vm.namespace("popover"),
          attrs: {
            skin: _vm.skin,
            "triggered-by": _vm.popoverTriggerId,
            showSearchField: _vm.popoverShowSearch,
            visible: _vm.popoverVisible,
            width: _vm.popoverWidth,
            priority: _vm.popoverPriority,
            positionPriority: _vm.popoverPositionPriority,
            directionPriority: _vm.popoverDirectionPriority,
            title: _vm.popoverTitle,
            searchFieldPlaceholder: _vm.searchFieldPlaceholder
          },
          on: {
            hide: _vm.hideSettingsPopover,
            "search-key-changed": _vm.filterCurrencies
          }
        },
        [
          _c("markets-ui-list", {
            attrs: {
              slot: "content",
              skin: _vm.skin,
              dataModel: _vm.currencyOptions
            },
            on: { change: _vm.exchangeCurrency },
            slot: "content"
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }