var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.namespace() }, [
    _c(
      "div",
      { class: _vm.namespace("grid-container") },
      [
        _c("markets-ui-grid", {
          attrs: {
            skin: _vm.skin,
            groupKey: _vm.groupKey,
            formatter: _vm.formatter,
            rows: _vm.rows,
            columns: _vm.columns,
            option: _vm.settings,
            labels: _vm.labels,
            caption: _vm.labels.forexTableCaption
          },
          on: { "visible-rows-update": _vm.visibleRowsUpdate }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }