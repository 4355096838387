import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;

export const COMMON_DATA_POINTS = [
    {
        id: 'name',
        width: 100,
        dataType: 'tree'
    },
    {
        id: 'bidPrice',
        align: 'right',
        width: 100,
        dataType: 'number'
    },
    {
        id: 'askPrice',
        align: 'right',
        width: 100,
        dataType: 'number'
    },
    {
        id: 'chg',
        dataType: 'upDown',
        flash: 'color',
        align: 'right',
        width: 100
    },
    {
        id: 'chg%',
        dataType: 'upDownWithPercent',
        formatType: 'percent',
        flash: 'color',
        align: 'right'
    },
    {
        id: 'highPrice',
        align: 'right',
        width: 100,
        dataType: 'number'
    },
    {
        id: 'lowPrice',
        align: 'right',
        width: 100,
        dataType: 'number'
    },
    {
        id: 'spread',
        dataType: 'number'
    },
    {
        id: 'numberOfTradesSinceMarketOpen',
        dataType: 'number'
    }
];

const MONITOR_DATA_POINTS = {
    BidPrice: [
        {
            id: 'bidTime',
            dataType: 'time',
            align: 'right'
        },
        {
            id: 'bidDate',
            dataType: 'date',
            align: 'right'
        }
    ],
    AskPrice: [
        {
            id: 'askTime',
            dataType: 'time',
            align: 'right'
        },
        {
            id: 'askDate',
            dataType: 'date',
            align: 'right'
        }
    ]
};

export const getAllDataPoints = () => {
    return [
        ...COMMON_DATA_POINTS,
        ...MONITOR_DATA_POINTS.BidPrice,
        ...MONITOR_DATA_POINTS.AskPrice
    ];
};

export const getNumberDataPoints = () => {
    const allDataPoints = getAllDataPoints();
    return allDataPoints.filter(dp => {
        return (
            dp.dataType === 'number' ||
            dp.dataType === 'upDown' ||
            dp.dataType === 'upDownWithPercent'
        );
    });
};

export const getDataPointsByIds = (idArray = []) => {
    const allDataPoints = [
        ...COMMON_DATA_POINTS,
        ...MONITOR_DATA_POINTS.BidPrice,
        ...MONITOR_DATA_POINTS.AskPrice
    ];
    return allDataPoints.filter(item => {
        return utils.inArray(idArray, item.id) > -1;
    });
};
