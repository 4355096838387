const currencyMap = {
    'USD,CAN,CAD,ARS,AUD,BSD,BBD,BMD,BND,KYD,CLP,COP,XCD,SVC,FJD,GYD,LRD,MXN,NAD,NZD,SGD,SBD,SOS,SRD,TVD': {
        unicode: '\u0024',
        symbol: '$'
    },
    'GBP,EGP,FKP,GIP,GGP,IMP,JEP,LBP,SHP,SYP': {
        unicode: '\u00a3',
        symbol: '£'
    },
    'GBX,BWP': {
        unicode: '\u0050',
        symbol: 'P',
        orient: 'right'
    },
    EUR: {
        unicode: '\u20ac',
        symbol: '€',
        orient: 'right'
    },
    'ANG,AWG': {
        unicode: '\u0192',
        symbol: 'ƒ'
    },
    'CNY,JPY': {
        unicode: '\u00a5',
        symbol: '¥'
    },
    GHC: {
        unicode: '\u00a2',
        symbol: '¢'
    },
    ALL: {
        unicode: '\u004c\u0065\u006b',
        symbol: 'Lek'
    },
    AFN: {
        unicode: '\u060b',
        symbol: '؋'
    },
    AZN: {
        unicode: '\u043c\u0430\u043d',
        symbol: 'ман'
    },
    BYR: {
        unicode: '\u0070\u002e',
        symbol: 'p.'
    },
    BZD: {
        unicode: '\u0042\u005a\u0024',
        symbol: 'BZ$'
    },
    BOB: {
        unicode: '\u0024\u0062',
        symbol: '$b'
    },
    BAM: {
        unicode: '\u004b\u004d',
        symbol: 'KM'
    },
    'BGN,KZT,KGS,UZS': {
        unicode: '\u043b\u0432',
        symbol: 'лв'
    },
    BRL: {
        unicode: '\u0052\u0024',
        symbol: 'R$'
    },
    KHR: {
        unicode: '\u17db',
        symbol: '៛'
    },
    CRC: {
        unicode: '\u20a1',
        symbol: '₡'
    },
    HRK: {
        unicode: '\u006b\u006e',
        symbol: 'kn'
    },
    CUP: {
        unicode: '\u20b1',
        symbol: '₱'
    },
    CZK: {
        unicode: '\u004b\u010d',
        symbol: 'Kč'
    },
    'DKK,EEK,ISK,NOK,SEK': {
        unicode: '\u006b\u0072',
        symbol: 'kr',
        orient: 'right'
    },
    DOP: {
        unicode: '\u0052\u0044\u0024',
        symbol: 'RD$'
    },
    GTQ: {
        unicode: '\u0051',
        symbol: 'Q'
    },
    HNL: {
        unicode: '\u004c',
        symbol: 'L'
    },
    HKD: {
        unicode: '\u0048\u004b\u0024',
        symbol: '$'
    },
    HUF: {
        unicode: '\u0046\u0074',
        symbol: 'Ft'
    },
    'MUR,NPR,PKR,SCR,LKR': {
        unicode: '\u20a8',
        symbol: '₨'
    },
    INR: {
        unicode: '\u20b9',
        symbol: '₹'
    },
    IDR: {
        unicode: '\u0052\u0070',
        symbol: 'Rp'
    },
    'OMR,QAR,SAR,YER': {
        unicode: '\ufdfc',
        symbol: '﷼'
    },
    ILS: {
        unicode: '\u20aa',
        symbol: '₪'
    },
    JMD: {
        unicode: '\u004a\u0024',
        symbol: 'J$'
    },
    'KPW,KRW,KPW,KRW': {
        unicode: '\u20a9',
        symbol: '₩'
    },
    LAK: {
        unicode: '\u20ad',
        symbol: '₭'
    },
    Ls: {
        unicode: '\u004c\u0073',
        symbol: 'Ls'
    },
    CHF: {
        unicode: '\u0043\u0048\u0046',
        symbol: 'CHF'
    },
    LTL: {
        unicode: '\u004c\u0074',
        symbol: 'Lt'
    },
    MKD: {
        unicode: '\u0434\u0435\u0414',
        symbol: 'ден'
    },
    MYR: {
        unicode: '\u0052\u004d',
        symbol: 'RM'
    },
    MNT: {
        unicode: '\u20ae',
        symbol: '₮'
    },
    NIO: {
        unicode: '\u0043\u0024',
        symbol: 'C$'
    },
    NGN: {
        unicode: '\u20a6',
        symbol: '₦'
    },
    PAB: {
        unicode: '\u0042\u002f\u002e',
        symbol: 'B/.'
    },
    PYG: {
        unicode: '\u0047\u0073',
        symbol: 'Gs'
    },
    PEN: {
        unicode: '\u0053\u002f\u002e',
        symbol: 'S/.'
    },
    PHP: {
        unicode: '\u20b1',
        symbol: '₱'
    },
    PLN: {
        unicode: '\u007a\u0142',
        symbol: 'zł'
    },
    RON: {
        unicode: '\u006c\u0065\u0069',
        symbol: 'lei'
    },
    RUB: {
        unicode: '\u0400\u0443\u0431',
        symbol: 'руб'
    },
    RSD: {
        unicode: '\u0420\u0421\u0414',
        symbol: 'РСД'
    },
    ZAR: {
        unicode: '\u0052',
        symbol: 'R'
    },
    TWD: {
        unicode: '\u004e\u0054\u0024',
        symbol: 'NT$'
    },
    THB: {
        unicode: '\u0e3f',
        symbol: '฿'
    },
    TTD: {
        unicode: '\u0054\u0054\u0024',
        symbol: 'TT$'
    },
    TRY: {
        unicode: '\u20ba',
        symbol: '₺'
    },
    TRL: {
        unicode: '\u20a4',
        symbol: '₤'
    },
    UAH: {
        unicode: '\u20b4',
        symbol: '₴'
    },
    UYU: {
        unicode: '\u0024\u0055',
        symbol: '$U'
    },
    VEF: {
        unicode: '\u0042\u0073',
        symbol: 'Bs'
    },
    VND: {
        unicode: '\u20ab',
        symbol: '₫'
    },
    ZWD: {
        unicode: '\u005a\u0024',
        symbol: 'Z$'
    },
    KWD: {
        unicode: '\u062f\u002e\u0643',
        symbol: 'د.ك'
    },
    AED: {
        unicode: '\u062f\u002e\u0625',
        symbol: 'د.إ'
    }
};
export function getCurrency(currency, key) {
    let currencyObject;
    for (const curr in currencyMap) {
        const currArray = curr.split(',');
        if (currArray.indexOf(currency) > -1) {
            currencyObject = currencyMap[curr];
            break;
        }
    }
    if (key && currencyObject) {
        return currencyObject[key];
    } else {
        return currencyObject;
    }
}

export function getPriceWithCurrency(price, currency) {
    const currencyObj = getCurrency(currency);
    if (!currencyObj) {
        return price;
    } else {
        if (currencyObj.orient === 'right') {
            return `${price}${currencyObj.symbol}`;
        } else {
            return `${currencyObj.symbol}${price}`;
        }
    }
}
