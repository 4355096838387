<template>
    <markets-ui-container
        :class="classes"
        :autoHeight="watchedSettings.autoHeight"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <div
            slot="content"
            v-if="showWebLayout && !errorMessage"
            role="navigation"
            aria-label="Component Settings"
            :class="[namespace('navigation-setting')]"
        >
            <div :class="[namespace('navigation-setting-button-group')]">
                <markets-ui-menus
                    :dataModel="viewTypeOptions"
                    @change="changeViewType"
                    :class="namespace('view-menu')"
                    :skin="watchedSettings.skin"
                ></markets-ui-menus>
                <markets-ui-menus
                    v-if="_isTable()"
                    :dataModel="groupOptions"
                    :class="namespace('group-menu')"
                    @change="changeTableGroup"
                    :collapseMode="tableGroupingCollapse"
                    :skin="watchedSettings.skin"
                ></markets-ui-menus>
            </div>
            <markets-ui-button
                icon-right="caret-down"
                :text="initedLabels['currencies']"
                :skin="watchedSettings.skin"
                :aria-expanded="currencyExpanded"
                :class="namespace('currency-menu')"
                @click="handleCurrenciesClick"
            ></markets-ui-button>
        </div>
        <div slot="content" :class="bodyClasses" v-if="!errorMessage">
            <TABLE
                v-if="_isTable()"
                :dataModel="tableDataModel"
                :skin="watchedSettings.skin"
                :labels="initedLabels"
                :formatter="formatter"
                :settings="tableSettings"
                @visible-rows-update="tableVisibleRowsUpdate"
            />
            <CROSSRATES
                v-if="_isCrossRates()"
                :dataModel="dataModel"
                :labels="initedLabels"
                :skin="watchedSettings.skin"
                :formatter="formatter"
                :monitor="watchedSettings.monitor"
                :contributorCode="watchedSettings.contributorCode"
                :horizonCurrencySet="selectedCurrencySet"
                :verticalCurrencySet="selectedCurrencySet"
            />
            <BOARD
                ref="board"
                v-if="_isBoard()"
                :dataModel="dataModel"
                :skin="watchedSettings.skin"
                :formatter="formatter"
                :labels="initedLabels"
                :currencySet="watchedSettings.currencySet"
                :selectedCurrencySet="selectedCurrencySet"
                :searchFieldPlaceholder="initedLabels.searchCurrencies"
                :contributorCode="watchedSettings.contributorCode"
                @symbol-changed="boardSymbolChanged"
            />
        </div>
        <markets-ui-settings
            slot="settings"
            :skin="watchedSettings.skin"
            :showBack="settingsShowBack"
            :triggered-by="settingsPopoverTriggerId"
            :title="settingsTitle"
            :done="initedLabels.done"
            :visible="settingsPopoverVisible"
            :width="settingsPopoverWidth"
            :showDone="currentListShow && !!changedCurrencies"
            :showSearchField="addCurrencyShow"
            :class="settingsPopoverClass"
            :doneLabel="initedLabels.done"
            :searchFieldPlaceholder="initedLabels.searchCurrencies"
            ref="settings"
            @hide="hideSettingsPopover"
            @done="changeCurrencyList"
            @back="changeSettingsNav"
            @search-key-changed="filterCurrencies"
        >
            <markets-ui-settings-navigation
                v-if="!settingsPopoverNavSelected"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNav"
                @change="changeSettingsNav"
                slot="content"
            />
            <span
                v-if="currentListShow"
                :class="namespace('settings-currency-tips')"
                slot="content"
                >{{ settingsCurrencyTips }}</span
            >
            <markets-ui-list
                size="small"
                v-if="!!settingsPopoverNavSelected && !currentListShow"
                :skin="watchedSettings.skin"
                :dataModel="settingsPopoverNavSelectedList"
                @change="changeSettingsItemSelected"
                variation="selection"
                slot="content"
            />
            <DRAGABLELIST
                :dataModel="currentCurrencyList"
                :skin="watchedSettings.skin"
                :minNumber="minCount"
                :labels="initedLabels"
                v-if="currentListShow"
                @change="changeCurrency"
                slot="content"
            ></DRAGABLELIST>
            <section
                :class="namespace('settings-currency__footer')"
                v-if="currentListShow"
                slot="content"
            >
                <markets-ui-button
                    :class="namespace('add-currency')"
                    variation="flat"
                    icon="plus"
                    :skin="watchedSettings.skin"
                    :text="initedLabels.addCurrency"
                    :disabled="isAtMaxCount"
                    @click.stop="addCurrencyClicked"
                />
                <div :class="namespace('currency-detail')" aria-live="off">
                    <span :class="namespace('currency__count')">{{
                        currencyCountInfo
                    }}</span>

                    <markets-ui-button
                        :class="namespace('currency__reset')"
                        variation="flat"
                        :skin="watchedSettings.skin"
                        :text="initedLabels.reset"
                        @click.stop="resetToDefault"
                    />
                </div>
            </section>
        </markets-ui-settings>
    </markets-ui-container>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import TABLE from './components/table';
import CROSSRATES from './components/cross-rates';
import BOARD from './components/board';
import DRAGABLELIST from '@core/src/ui/dragable-list/dragable-list';
import { getAllDataPoints } from './metadata/data-point';
import {
    getCurrencyLabel,
    getCurrencyPairByCurrencies,
    getRTSymbol,
    getSymbolByCurrencies,
    matchedKeywords,
    VIEWTYPES
} from './common';

const { utils, mixins } = mwcMarketsCore;
export default {
    name: 'mwc-markets-forex-ui',
    mixins: [mixins.componentUI],
    components: {
        TABLE,
        CROSSRATES,
        BOARD,
        DRAGABLELIST
    },
    props: {
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            watchedSettings: {
                currencySet: [],
                viewTypeOptions: [],
                groupOptions: []
            },
            searchKey: '',
            changedCurrencies: null,
            settingsPopoverVisible: false,
            settingsPopoverTriggerId: null,
            settingsPopoverNavSelected: undefined,
            settingsPopoverWidth: 200,
            tableGroupingCollapse: 'extend'
        };
    },
    computed: {
        classes() {
            return [this.namespace()];
        },
        bodyClasses() {
            const array = [this.namespace('body')];
            if (this.showWebLayout) {
                array.push(this.namespace('content--web-layout'));
            }
            return array;
        },
        settingsPopoverClass() {
            const cls = [
                this.namespace('popover'),
                this.namespace(
                    `${this.watchedSettings.viewType}-settings-popover`
                )
            ];
            if (this.addCurrencyShow) {
                cls.push(this.namespace('popover-add-currency'));
            }
            if (this.currentListShow) {
                cls.push(this.namespace('popover-edit-currency'));
            }

            return cls;
        },
        selectedCurrencySet() {
            if (this._isTable()) {
                return (
                    this.watchedSettings.tableSelectedCurrencySet ||
                    this.watchedSettings.currencySet
                );
            } else {
                return this.watchedSettings[
                    `${this.watchedSettings.viewType}SelectedCurrencySet`
                ];
            }
        },
        symbol() {
            let symbols = [],
                currencySet = [];
            if (this._isTable() || this._isCrossRates()) {
                currencySet = this._isTable()
                    ? this.watchedSettings.tableSelectedCurrencySet ||
                      this.watchedSettings.currencySet
                    : this.watchedSettings.crossRatesSelectedCurrencySet;
                symbols = getSymbolByCurrencies(
                    currencySet,
                    currencySet,
                    this.watchedSettings.contributorCode
                );
            } else if (this._isBoard()) {
                currencySet = this.watchedSettings.boardSelectedCurrencySet;
                currencySet.forEach(cur => {
                    symbols.push(
                        getRTSymbol(
                            cur.split('/').join(''),
                            this.watchedSettings.contributorCode
                        )
                    );
                });
            }
            return symbols;
        },
        tableDataModel() {
            const data = {};
            if (this._isTable()) {
                Object.keys(this.dataModel).forEach(key => {
                    if (utils.inArray(this.symbol, key) > -1) {
                        data[key] = this.dataModel[key];
                    }
                });
                return data;
            }
            return {};
        },
        securities() {
            return this.symbol.map(s => {
                return s.replace(/\d|\./g, '');
            });
        },
        tableSettings() {
            const option = {};
            [
                'tableSortable',
                'tableRemovable',
                'tableRowHeight',
                'tableFrozenColumn',
                'tableDataPoints',
                'tableGroupKey',
                'autoHeight',
                'tableLayout',
                'tableRowsPerPage',
                'tableRowsPerPageList',
                'showPageInfo',
                'showPageSelect',
                'tableStickyLeadBreakPoints'
            ].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option[
                        utils.lowerCaseFirstLetter(key.replace('table', ''))
                    ] = this.watchedSettings[key];
                }
            });
            return option;
        },
        minCount() {
            if (this._isBoard()) {
                return 1;
            } else {
                return this.settings.crossRatesCurrencyCount[0];
            }
        },
        maxCount() {
            if (this._isBoard()) {
                return getCurrencyPairByCurrencies(
                    this.watchedSettings.currencySet
                ).length;
            } else if (this._isTable()) {
                return this.watchedSettings.currencySet.length;
            } else {
                return this.settings.crossRatesCurrencyCount[1];
            }
        },
        isAtMaxCount() {
            return this.maxCount <= this.currentCurrencyList.length;
        },
        currencyCountInfo() {
            const max = this.maxCount;
            const num = this.changedCurrencies
                ? this.changedCurrencies.length
                : this.selectedCurrencySet.length;
            return `${num}/${max}`;
        },
        settingsTitle() {
            if (this.settingsPopoverNavSelected) {
                return this.settingsPopoverNavSelected.title;
            } else {
                return this.initedLabels['settingTitle'];
            }
        },
        settingsShowBack() {
            if (this.showWebLayout) {
                return this.addCurrencyShow ? true : false;
            }
            return !!this.settingsPopoverNavSelected;
        },
        settingsCurrencyTips() {
            const currCount = this.settings.crossRatesCurrencyCount;
            let selectTxt = '',
                defaultTxt = '';
            if (currCount) {
                selectTxt = this.initedLabels['settingsCurrencySelectTxt']
                    .replace('{0}', currCount[0])
                    .replace('{1}', currCount[1]);
                defaultTxt = this.initedLabels['settingsCurrencyDefaultTxt'];
            }
            if (this._isBoard()) {
                selectTxt = this.initedLabels['settingsCurrencySelectTxtBoard'];
            }
            if (!this.changedCurrencies) {
                return `${defaultTxt}${selectTxt}`;
            } else {
                return selectTxt;
            }
        },
        settingsPopoverNav() {
            const popoverOption = [],
                selectedCurrency =
                    this.changedCurrencies || this.selectedCurrencySet;
            popoverOption.push({
                id: 'currencies',
                name: `${selectedCurrency.length} ${this.initedLabels['selected']}`,
                title: this.initedLabels['currencies']
            });
            if (this._isTable()) {
                popoverOption.push({
                    id: 'grouping',
                    name: this.initedLabels[this.watchedSettings.tableGroupKey],
                    title: this.initedLabels['grouping']
                });
            }
            popoverOption.push({
                id: 'dataView',
                name: this.initedLabels[this.watchedSettings.viewType],
                title: this.initedLabels['dataView']
            });
            return popoverOption;
        },
        currentListShow() {
            return (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'currencies'
            );
        },
        addCurrencyShow() {
            return (
                this.settingsPopoverNavSelected &&
                this.settingsPopoverNavSelected.id === 'addCurrency'
            );
        },
        currentCurrencyList() {
            let list = [];
            const currencyList =
                this.changedCurrencies || this.selectedCurrencySet;
            if (this._isBoard() || this._isTable()) {
                list = currencyList.map(currency => {
                    return {
                        id: currency,
                        metadataBelow: currency,
                        name: getCurrencyLabel(currency, this.initedLabels)
                    };
                });
            } else if (this._isCrossRates()) {
                if (currencyList) {
                    list = currencyList.map(currency => {
                        return {
                            id: currency,
                            metadataBelow: currency,
                            name: this.initedLabels[currency]
                        };
                    });
                }
            }
            return list;
        },
        visibleCurrencies() {
            let allCurrencies = [];
            if (this._isCrossRates() || this._isTable()) {
                allCurrencies = this.watchedSettings.currencySet;
            } else {
                allCurrencies = getCurrencyPairByCurrencies(
                    this.watchedSettings.currencySet,
                    '/'
                );
            }
            const listCurrency = this.changedCurrencies
                ? this.changedCurrencies
                : this.selectedCurrencySet;
            const restCurrencies = allCurrencies.filter(c => {
                return utils.inArray(listCurrency, c) < 0;
            });
            let visibleCurrencies = restCurrencies;
            if (this.searchKey) {
                visibleCurrencies = restCurrencies.filter(c => {
                    return matchedKeywords(
                        c,
                        this.searchKey,
                        this.initedLabels
                    );
                });
            }
            return visibleCurrencies;
        },
        viewTypeOptions() {
            return this.watchedSettings.viewTypeOptions.map(view => {
                return {
                    id: view,
                    name: this.initedLabels[view],
                    selected: view === this.watchedSettings.viewType
                };
            });
        },
        groupOptions() {
            const options = this.watchedSettings.groupOptions.map(group => {
                return {
                    id: group,
                    name: this.initedLabels[group],
                    selected: group === this.watchedSettings.tableGroupKey
                };
            });
            // in the web layout the grouping show label grouping/noGrouping
            // in the not web layout, the grouping show label currency/noGrouping
            if (this.showWebLayout) {
                const group = utils.find(options, op => op.id === 'currency');
                if (group) {
                    group.name = this.initedLabels['grouping'];
                }
            }
            return options;
        },
        settingsPopoverNavSelectedList() {
            if (this.settingsPopoverNavSelected.id === 'dataView') {
                return this.viewTypeOptions;
            } else if (this.settingsPopoverNavSelected.id === 'grouping') {
                return this.groupOptions;
            }
            if (this.addCurrencyShow) {
                return this.visibleCurrencies.map(currency => {
                    return {
                        id: currency,
                        selected: false,
                        name: getCurrencyLabel(currency, this.initedLabels),
                        metadataBelow: currency
                    };
                });
            }
            return [];
        },
        currencyExpanded() {
            return (
                '' +
                (this.settingsPopoverVisible &&
                    this.changeSettingsNav &&
                    this.settingsPopoverNavSelected.id === 'currency')
            );
        }
    },
    watch: {
        symbol(value) {
            if (value) {
                this.$emit('symbol-changed', value);
            }
        },
        'watchedSettings.viewType': function handler(value) {
            if (value) {
                // Tracking: number of usage on each view (cross rates/table/board).
                this.trackEvent({
                    name: 'view-type-change',
                    value
                });
            }
        },
        'watchedSettings.crossRatesSelectedCurrencySet': function handler(
            currencies
        ) {
            if (
                this.watchedSettings.viewType === VIEWTYPES.CROSSRATES &&
                currencies &&
                utils.isArray(currencies)
            ) {
                // Tracking: number of user set each currency in the cross rates view.
                currencies.forEach(currency => {
                    this.trackEvent({
                        name: 'cross-rates-currency-select',
                        value: currency
                    });
                });
            }
        },
        'watchedSettings.boardSelectedCurrencySet': function handler(
            currencies
        ) {
            if (
                this.watchedSettings.viewType === VIEWTYPES.BOARD &&
                currencies &&
                utils.isArray(currencies)
            ) {
                currencies.forEach(currency => {
                    // Tracking: number of user set each currency in the board view.
                    this.trackEvent({
                        name: 'board-currency-select',
                        value: currency
                    });
                });
            }
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('forex');
        this.allDataPoints = getAllDataPoints();
        if (!this.watchedSettings.tableGroupKey) {
            this.watchedSettings.tableGroupKey = 'noGrouping';
        }
    },
    methods: {
        toggleSettings(e, currentTarget) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggerId = utils.computeTriggerId(
                currentTarget ? e.currentTarget : e.target
            );
        },
        getForexSymbolByCurrencies(currencySet) {
            const symbolSet = [];
            currencySet.forEach(cur1 => {
                currencySet.forEach(cur2 => {
                    symbolSet.push(
                        getRTSymbol(
                            `${cur1}${cur2}`,
                            this.watchedSettings.contributorCode
                        )
                    );
                });
            });
            return symbolSet;
        },
        tableVisibleRowsUpdate(symbols) {
            this.$emit('visible-rows-update', symbols);
        },
        resetToDefault() {
            this.watchedSettings[
                `${this.watchedSettings.viewType}SelectedCurrencySet`
            ] = this.watchedSettings[
                `${this.watchedSettings.viewType}DefaultCurrencySet`
            ];
            this.changedCurrencies = null;
        },
        changeCurrency(currency) {
            this.changedCurrencies = currency.map(c => c.id);
        },
        boardSymbolChanged(symbol) {
            this.watchedSettings.boardSelectedCurrencySet = utils.extend(
                true,
                [],
                symbol
            );
        },
        addCurrencyClicked(e) {
            if (this.isAtMaxCount) {
                return;
            }
            this.previousSelectedNaV = utils.extend(
                true,
                {},
                this.settingsPopoverNavSelected
            );
            this.settingsPopoverNavSelected = {
                id: 'addCurrency',
                title: this.initedLabels['addCurrency']
            };
            if (this.$refs.settings) {
                this.$refs.settings.enableTrapFocus();
            }
        },
        changeCurrencyList() {
            if (this.changedCurrencies) {
                this.watchedSettings[
                    `${this.watchedSettings.viewType}SelectedCurrencySet`
                ] = this.changedCurrencies.map(currency => {
                    return currency;
                });
            }
            this.changeSettingsNav();
            this.hideSettingsPopover();
            this.changeSettings(this._getStoreSettings());
        },
        filterCurrencies(keyWord) {
            this.searchKey = keyWord;
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.changedCurrencies = null;
            this.settingsPopoverNavSelected = null;
        },
        handleCurrenciesClick(e) {
            const item = utils.find(
                this.settingsPopoverNav,
                item => item.id === 'currencies'
            );
            if (item) {
                this.toggleSettings(e, true);
                this.changeSettingsNav(item);
            }
        },
        changeSettingsNav(item) {
            if (!item && this.addCurrencyShow) {
                item = utils.extend(true, {}, this.previousSelectedNaV);
            }
            this.settingsPopoverNavSelected = item;
            this.$refs.settings.enableTrapFocus();
        },
        changeViewType(item) {
            this.watchedSettings.viewType = item.id;
            this.changeSettings(this._getStoreSettings());
        },
        changeTableGroup(item) {
            this.watchedSettings.tableGroupKey = item.id;
            this.changeSettings(this._getStoreSettings());
        },
        changeSettingsItemSelected(item) {
            if (this.settingsPopoverNavSelected) {
                if (this.settingsPopoverNavSelected.id === 'dataView') {
                    this.hideSettingsPopover();
                    this.changeViewType(item);
                } else if (this.settingsPopoverNavSelected.id === 'grouping') {
                    this.hideSettingsPopover();
                    this.changeTableGroup(item);
                } else if (this.addCurrencyShow) {
                    this.changeSettingsNav();
                    this.changedCurrencies =
                        this.changedCurrencies ||
                        utils.extend(true, [], this.selectedCurrencySet);
                    this.changedCurrencies.push(item.id);
                }
            }
        },
        // FIXME: these three methods can be one.
        _isBoard() {
            return this.watchedSettings.viewType === VIEWTYPES.BOARD;
        },
        _isTable() {
            return this.watchedSettings.viewType === VIEWTYPES.TABLE;
        },
        _isCrossRates() {
            return this.watchedSettings.viewType === VIEWTYPES.CROSSRATES;
        },
        _getStoreSettings() {
            return {
                viewType: this.watchedSettings.viewType,
                tableGroupKey: this.watchedSettings.tableGroupKey,
                boardSelectedCurrencySet: this.watchedSettings.boardSelectedCurrencySet.join(
                    ','
                ),
                crossRatesSelectedCurrencySet: this.watchedSettings.crossRatesSelectedCurrencySet.join(
                    ','
                ),
                tableSelectedCurrencySet: this.watchedSettings.tableSelectedCurrencySet.join(
                    ','
                )
            };
        },
        _resizeCallback({ entry }) {
            const width = entry.contentRect.width;
            if (width < 465) {
                this.tableGroupingCollapse = 'collapse';
            } else {
                this.tableGroupingCollapse = 'extend';
            }
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-forex';
.#{$namespace} {
    * {
        box-sizing: border-box;
    }

    font-weight: $mds-typography-font-weight-light;
    &-popover {
        &.mds-popover___markets.mds-popover--width-200px___markets {
            width: 220px;
        }
    }
    &-popover-edit-currency,
    &-popover-add-currency {
        .markets-ui-settings__content {
            max-height: 330px;
        }
        .mds-list-group__item-text___markets {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &.mds-popover___markets.mds-popover--width-200px___markets {
            width: 250px;
        }
        .markets-ui-dragable-list {
            max-height: 175px;
        }
        .mds-list-group__item-text___markets {
            width: 170px;
        }
        .markets-ui-list .mds-list-group__item-metadata-below-item___markets {
            font-weight: $mds-typography-font-weight-light;
        }
        .#{$namespace}-add-currency {
            margin-left: 4px;
        }
        .#{$namespace}-settings-currency__footer .mds-button__icon___markets {
            margin-left: -8px;
        }
    }
    &-popover-add-currency {
        .markets-ui-list .mds-list-group__link___markets {
            padding: 0;
        }
    }

    &-board-settings-popover.markets-ui-popover {
        &.#{$namespace}-popover-edit-currency,
        &.#{$namespace}-popover-add-currency {
            width: 350px;

            .mds-list-group__item-text___markets {
                width: 275px;
            }
        }
        &.#{$namespace}-popover-edit-currency
            .mds-list-group__item-text___markets {
            padding-left: 16px;
        }
        .markets-ui-dragable-list__item-label {
            flex-basis: 210px;
        }
        .markets-ui-dragable-list__item-name {
            flex-basis: 55px;
        }
        .#{$namespace}-settings-currency-label {
            width: 205px;
        }
    }
    &-settings-currency-tips {
        margin-bottom: 10px;
        display: inline-block;
    }
    &-plus-icon {
        width: 12px;
        height: 12px;
    }
    &-settings-currency__footer {
        margin-top: 10px;
        font-size: $mds-typography-font-size-s;
        .#{$namespace}__link {
            &:hover {
                fill: $mds-text-color-interactive-primary;
            }
        }
    }
    &-disabled {
        cursor: not-allowed;
    }

    &-currency-detail {
        display: flex;
        justify-content: space-between;
        padding: 4px 4px;
    }
    &-currency__reset {
        font-weight: $mds-typography-font-weight-bold;
        color: $mds-color-neutral-50;
        cursor: pointer;
    }
    &-currency__add {
        margin-right: 2px;
    }
    &-settings-currency-tips,
    &-settings-currency-label,
    &-settings-currency {
        font-size: $mds-typography-font-size-s;
    }
    &-settings-currency-label {
        display: inline-block;
        width: 140px;
    }
    &-crossRates-settings-popover {
        z-index: 2;
    }
    &-navigation-setting {
        height: 35px;
        padding-top: $mds-space-1-x;
        margin-bottom: $mds-space-1-x;
        display: flex;
        width: 100%;
        &-button-group {
            display: flex;
        }
        padding: $mds-space-half-x;
    }
    &-group-menu,
    &-currency-menu {
        margin-left: $mds-space-1-x;
    }

    &.markets-ui-container__fixed-height {
        .#{$namespace}-body {
            height: 100%;
            overflow: auto;
        }
        .#{$namespace}-content--web-layout {
            height: calc(100% - 43px);
        }
    }
}

.#{$namespace}.markets-ui-container__dark-gray {
    background: $mds-background-color-dark-gray;
    color: $mds-text-color-primary-on-dark;
}
</style>
