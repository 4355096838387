import { removeElement, openHyperlink } from './lang';
export async function exportToFile(data, name) {
    let blob;
    if (data instanceof Blob) {
        blob = data;
    } else {
        blob = new Blob([data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
        });
    }

    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, name);
    } else {
        const URL = window.URL || window.webkitURL;
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = objectUrl;
        link.setAttribute('download', name);

        if (typeof link.download === 'undefined') {
            link.setAttribute('target', '_blank');
        }
        document.body.appendChild(link);
        link.click();
        removeElement(link);
        window.URL.revokeObjectURL(objectUrl);
    }
}

export function exportForIframe(params) {
    let iframe = document.querySelector('#mwc-market-export-iframe');
    if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.name = 'formsubmit';
        iframe.id = 'mwc-market-export-iframe';
        iframe.style.display = 'none';
    }
    document.body.appendChild(iframe);
    openHyperlink(params);
}
