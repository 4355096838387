//from RDB Mapping
export const Security_Types = {
    ST: 'ST',
    XI: 'XI',
    FE: 'FE',
    FC: 'FC',
    FO: 'FO',
    FM: 'FM',
    VA: 'VA',
    VL: 'VL',
    FH: 'FH',
    SA: 'SA',
    CZ: 'CZ',
    FI: 'FI',
    FV: 'FV',
    PS: 'PS',
    CT: 'CT',
    V1: 'V1',
    VP: 'VP',
    LP: 'LP',
    MO: 'MO',
    R1: 'R1',
    SP: 'SP',
    CA: 'CA',
    Forex: 'Forex',
    '1': 'ST',
    '2': 'Option',
    '3': 'Future',
    '7': 'Bond',
    '8': 'FO',
    '9': 'Bond',
    '10': 'XI',
    '11': 'Bond',
    '13': 'StrategySpread',
    '15': 'FM',
    '16': 'Bond',
    '17': 'Certificates',
    '18': 'Warrants',
    '20': 'Forex'
};

//for autocomplete transform
export const Autocomplete_Sec_Types = {
    ST: 'stock',
    FO: 'mutualFund',
    XI: 'index',
    FE: 'ETF',
    FC: 'CEF',
    FM: 'FM',
    VA: 'VA',
    VL: 'VL',
    FH: 'FH',
    SA: 'SA',
    CZ: 'CZ',
    FI: 'FI',
    FV: 'FV',
    PS: 'PS',
    CT: 'CT',
    V1: 'V1',
    VP: 'VP',
    LP: 'LP',
    MO: 'MO',
    R1: 'R1',
    SP: 'SP',
    CA: 'CA',
    Option: 'option',
    Future: 'future',
    StrategySpread: 'strategySpread',
    Bond: 'bond',
    Certificates: 'certificates',
    Warrants: 'warrants',
    Forex: 'forex'
};

// frontend common to transform
export const Sec_Types = {
    ST: 'ST', //stock
    XI: 'XI', //index
    FE: 'FE', //etf
    FC: 'FC', //cef
    FO: 'FO', //mutual fund
    FM: 'FO',
    VA: 'VA',
    VL: 'VL',
    FH: 'FH',
    SA: 'SA',
    CZ: 'CZ',
    FI: 'FI',
    FV: 'FV',
    PS: 'PS',
    CT: 'CT',
    V1: 'V1',
    VP: 'VP',
    LP: 'LP',
    MO: 'MO',
    R1: 'R1',
    SP: 'SP',
    CA: 'CA',
    Forex: 'Forex',
    Option: 'Option',
    Future: 'Future',
    Others: 'Others'
};

export function getSecurityType(security) {
    const type = security.mstarType || security.rtType;
    const securityType = Security_Types[type];
    return (securityType && Sec_Types[securityType]) || Sec_Types.Others;
}
