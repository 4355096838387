const KEY_CODE = Object.freeze({
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
});

function isESC(keycode) {
    return keycode === KEY_CODE.ESC;
}

function isEnter(keycode) {
    return keycode === KEY_CODE.ENTER;
}

function isSpace(keycode) {
    return keycode === KEY_CODE.SPACE;
}

function isDown(keycode) {
    return keycode === KEY_CODE.DOWN;
}
function isUp(keycode) {
    return keycode === KEY_CODE.UP;
}

function isTab(keyCode) {
    return keyCode === KEY_CODE.TAB;
}
export default {
    isESC,
    isEnter,
    isSpace,
    isDown,
    isUp,
    isTab
};
