var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.classes,
      attrs: {
        autoHeight: _vm.watchedSettings.autoHeight,
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _vm.showWebLayout && !_vm.errorMessage
        ? _c(
            "div",
            {
              class: [_vm.namespace("navigation-setting")],
              attrs: {
                slot: "content",
                role: "navigation",
                "aria-label": "Component Settings"
              },
              slot: "content"
            },
            [
              _c(
                "div",
                { class: [_vm.namespace("navigation-setting-button-group")] },
                [
                  _c("markets-ui-menus", {
                    class: _vm.namespace("view-menu"),
                    attrs: {
                      dataModel: _vm.viewTypeOptions,
                      skin: _vm.watchedSettings.skin
                    },
                    on: { change: _vm.changeViewType }
                  }),
                  _vm._v(" "),
                  _vm._isTable()
                    ? _c("markets-ui-menus", {
                        class: _vm.namespace("group-menu"),
                        attrs: {
                          dataModel: _vm.groupOptions,
                          collapseMode: _vm.tableGroupingCollapse,
                          skin: _vm.watchedSettings.skin
                        },
                        on: { change: _vm.changeTableGroup }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("markets-ui-button", {
                class: _vm.namespace("currency-menu"),
                attrs: {
                  "icon-right": "caret-down",
                  text: _vm.initedLabels["currencies"],
                  skin: _vm.watchedSettings.skin,
                  "aria-expanded": _vm.currencyExpanded
                },
                on: { click: _vm.handleCurrenciesClick }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.errorMessage
        ? _c(
            "div",
            {
              class: _vm.bodyClasses,
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _vm._isTable()
                ? _c("TABLE", {
                    attrs: {
                      dataModel: _vm.tableDataModel,
                      skin: _vm.watchedSettings.skin,
                      labels: _vm.initedLabels,
                      formatter: _vm.formatter,
                      settings: _vm.tableSettings
                    },
                    on: { "visible-rows-update": _vm.tableVisibleRowsUpdate }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._isCrossRates()
                ? _c("CROSSRATES", {
                    attrs: {
                      dataModel: _vm.dataModel,
                      labels: _vm.initedLabels,
                      skin: _vm.watchedSettings.skin,
                      formatter: _vm.formatter,
                      monitor: _vm.watchedSettings.monitor,
                      contributorCode: _vm.watchedSettings.contributorCode,
                      horizonCurrencySet: _vm.selectedCurrencySet,
                      verticalCurrencySet: _vm.selectedCurrencySet
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._isBoard()
                ? _c("BOARD", {
                    ref: "board",
                    attrs: {
                      dataModel: _vm.dataModel,
                      skin: _vm.watchedSettings.skin,
                      formatter: _vm.formatter,
                      labels: _vm.initedLabels,
                      currencySet: _vm.watchedSettings.currencySet,
                      selectedCurrencySet: _vm.selectedCurrencySet,
                      searchFieldPlaceholder: _vm.initedLabels.searchCurrencies,
                      contributorCode: _vm.watchedSettings.contributorCode
                    },
                    on: { "symbol-changed": _vm.boardSymbolChanged }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "settings",
          class: _vm.settingsPopoverClass,
          attrs: {
            slot: "settings",
            skin: _vm.watchedSettings.skin,
            showBack: _vm.settingsShowBack,
            "triggered-by": _vm.settingsPopoverTriggerId,
            title: _vm.settingsTitle,
            done: _vm.initedLabels.done,
            visible: _vm.settingsPopoverVisible,
            width: _vm.settingsPopoverWidth,
            showDone: _vm.currentListShow && !!_vm.changedCurrencies,
            showSearchField: _vm.addCurrencyShow,
            doneLabel: _vm.initedLabels.done,
            searchFieldPlaceholder: _vm.initedLabels.searchCurrencies
          },
          on: {
            hide: _vm.hideSettingsPopover,
            done: _vm.changeCurrencyList,
            back: _vm.changeSettingsNav,
            "search-key-changed": _vm.filterCurrencies
          },
          slot: "settings"
        },
        [
          !_vm.settingsPopoverNavSelected
            ? _c("markets-ui-settings-navigation", {
                attrs: {
                  slot: "content",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNav
                },
                on: { change: _vm.changeSettingsNav },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentListShow
            ? _c(
                "span",
                {
                  class: _vm.namespace("settings-currency-tips"),
                  attrs: { slot: "content" },
                  slot: "content"
                },
                [_vm._v(_vm._s(_vm.settingsCurrencyTips))]
              )
            : _vm._e(),
          _vm._v(" "),
          !!_vm.settingsPopoverNavSelected && !_vm.currentListShow
            ? _c("markets-ui-list", {
                attrs: {
                  slot: "content",
                  size: "small",
                  skin: _vm.watchedSettings.skin,
                  dataModel: _vm.settingsPopoverNavSelectedList,
                  variation: "selection"
                },
                on: { change: _vm.changeSettingsItemSelected },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentListShow
            ? _c("DRAGABLELIST", {
                attrs: {
                  slot: "content",
                  dataModel: _vm.currentCurrencyList,
                  skin: _vm.watchedSettings.skin,
                  minNumber: _vm.minCount,
                  labels: _vm.initedLabels
                },
                on: { change: _vm.changeCurrency },
                slot: "content"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.currentListShow
            ? _c(
                "section",
                {
                  class: _vm.namespace("settings-currency__footer"),
                  attrs: { slot: "content" },
                  slot: "content"
                },
                [
                  _c("markets-ui-button", {
                    class: _vm.namespace("add-currency"),
                    attrs: {
                      variation: "flat",
                      icon: "plus",
                      skin: _vm.watchedSettings.skin,
                      text: _vm.initedLabels.addCurrency,
                      disabled: _vm.isAtMaxCount
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addCurrencyClicked($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: _vm.namespace("currency-detail"),
                      attrs: { "aria-live": "off" }
                    },
                    [
                      _c("span", { class: _vm.namespace("currency__count") }, [
                        _vm._v(_vm._s(_vm.currencyCountInfo))
                      ]),
                      _vm._v(" "),
                      _c("markets-ui-button", {
                        class: _vm.namespace("currency__reset"),
                        attrs: {
                          variation: "flat",
                          skin: _vm.watchedSettings.skin,
                          text: _vm.initedLabels.reset
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.resetToDefault($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }