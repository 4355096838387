import mwcMarketsCore from 'mwc-markets-core';
const { utils } = mwcMarketsCore;
export default {
    name: 'mwc-markets-forex-basic-mixin',
    props: {
        skin: {
            type: String,
            default: ''
        },
        dataModel: {
            type: Object,
            default() {
                return {};
            }
        },
        formatter: {
            type: Object,
            default() {
                return {};
            }
        },
        labels: {
            type: Object,
            default() {
                return {};
            }
        },
        settings: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    methods: {
        moduleClassName(context) {
            return utils.moduleClassName(context);
        }
    }
};
