<template>
    <FOREXUI
        ref="UI"
        :mwcId="mwcId"
        :dataModel="dataModel"
        :skin="skin"
        :errorCode="error.code"
        :showLoading="showLoading"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        @track="trackEvent"
        @settings-changed="changeSettings"
        @symbol-changed="changeSymbol"
        @visible-rows-update="subscribeSymbols"
    >
    </FOREXUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import FOREXUI from './forex-ui';
import { getAllDataPoints, getNumberDataPoints } from './metadata/data-point';
const { utils, mixins } = mwcMarketsCore;
const CURRENCY_SET =
    'USD,GBP,EUR,JPY,CAD,CHF,CNY,BRL,CLP,COP,CUP,CZK,INR,IDR,IRR,IQD,LBP,MXN,NZD,PHP,SGD';
export default {
    name: 'mwc-markets-forex',
    components: {
        FOREXUI
    },
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    data() {
        return {
            defaultConfig: {
                settings: {
                    currencySet: CURRENCY_SET,
                    viewType: 'crossRates',
                    viewTypeOptions: 'crossRates,table,board',
                    groupOptions: 'currency,noGrouping',
                    monitor: 'BidPrice',
                    contributorCode: 'COMP',
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    languageId: 'en-US',
                    updateLastAccessTime: true,
                    dataType: 'pull',
                    tableDataPoints:
                        'name,bidPrice,chg,chg%,highPrice,lowPrice,bidTime,bidDate',
                    tableGroupKey: 'currency',
                    tableFrozenColumn: 0,
                    tableSortable: true,
                    crossRatesSelectedCurrencySet:
                        'USD,GBP,EUR,JPY,CAD,CHF,CNY,BRL', // the selected currency shows on crossrates, it could be saved currency set
                    crossRatesDefaultCurrencySet:
                        'USD,GBP,EUR,JPY,CAD,CHF,CNY,BRL', // when user click reset to default
                    crossRatesCurrencyCount: [2, 10],
                    boardSelectedCurrencySet:
                        'USD/CAD,EUR/USD,GBP/USD,AUD/USD,USD/JPY,USD/CNY,USD/CHF,USD/BRL', // the selected currency shows on board, it could be saved currency set
                    boardDefaultCurrencySet:
                        'USD/CAD,EUR/USD,GBP/USD,AUD/USD,USD/JPY,USD/CNY,USD/CHF,USD/BRL', // user click reset to default
                    tableDefaultCurrencySet: CURRENCY_SET,
                    tableSelectedCurrencySet: CURRENCY_SET, // the selected currency shows on table
                    skin: '',
                    autoHeight: true,
                    tableLayout: '',
                    tableRowsPerPage: 20,
                    tableRowsPerPageList: [10, 20, 50],
                    showPageInfo: true,
                    showPageSelect: true,
                    tableStickyLeadBreakPoints: [
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 2,
                            minWidth: 0,
                            maxWidth: 500
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 4,
                            minWidth: 500,
                            maxWidth: 640
                        },
                        {
                            stickyColIndices: [0],
                            columnsPerPage: 6,
                            minWidth: 640,
                            maxWidth: 960
                        }
                    ]
                },
                labels: labels,
                format: {
                    time: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                    },
                    date: {
                        dataType: 'date',
                        day: '2-digit',
                        month: 'numeric',
                        year: 'numeric'
                    },
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                    },
                    boardNumber: {
                        dataType: 'number',
                        maximumFractionDigits: 5,
                        minimumFractionDigits: 5
                    },
                    percent: {
                        dataType: 'number',
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                    }
                },
                intlNamespace: 'mwc-markets-forex'
            },
            dataModel: {},
            showLoading: false
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    created() {
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.currentDataType = null;
        this.cachedData = {};
        this.allDataPoints = getAllDataPoints().map(item => {
            return item.id;
        });
        this.numberDataPoints = getNumberDataPoints().map(item => {
            return item.id;
        });
        this.initialized = false;
        this.targetVisibility = false;
        this.changedSymbols = null;
        this.changeDataType(this.settings.dataType);
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        if (this.isSubscribing) {
            this._unsubscribe(this.currentDataType, this.subscribeList);
            this.isSubscribing = false;
        }
    },
    methods: {
        initialize() {
            this.initialized = true;
            this.showLoading = true;
            this.changeSymbol(this.changedSymbols);
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this.changeSymbol(this.changedSymbols);
                }
            } else {
                if (!this.targetVisibility) {
                    if (this.isSubscribing) {
                        this._unsubscribe(
                            this.currentDataType,
                            this.subscribeList
                        );
                        this.isSubscribing = false;
                    }
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            const oldValue = this.currentDataType;
            this._unsubscribe(oldValue, this.subscribeList);
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            if (this.subscribeList.length) {
                this._subscribe(this.currentDataType, this.subscribeList);
            }
        },
        changeSymbol(symbol, needFetchSnapshot = true) {
            this.changedSymbols = symbol;
            if (this.initialized && symbol) {
                let unsubscribeSymbols = [],
                    subscribeSymbols = [];
                if (this.subscribeList) {
                    unsubscribeSymbols = this.subscribeList.filter(s => {
                        return utils.inArray(symbol, s) < 0;
                    });
                    subscribeSymbols = symbol.filter(s => {
                        return utils.inArray(this.subscribeList, s) < 0;
                    });
                } else {
                    subscribeSymbols = utils.extend(true, [], symbol);
                }
                if (this.snapShortPromise) {
                    this.snapShortPromise.cancel();
                }
                if (unsubscribeSymbols.length) {
                    this._unsubscribe(this.currentDataType, unsubscribeSymbols);
                }
                if (subscribeSymbols.length) {
                    if (needFetchSnapshot) {
                        this.snapShortPromise = utils.cancelablePromise(
                            this.initSnapshot(subscribeSymbols)
                        );
                        this.snapShortPromise.promise
                            .then(() => {
                                this.subscribeList = symbol;
                                this._subscribe(
                                    this.currentDataType,
                                    subscribeSymbols
                                );
                                this.renderCompleted = true;
                            })
                            .catch(e => {
                                this.showLoading = false;
                            });
                    } else {
                        this._subscribe(this.currentDataType, subscribeSymbols);
                    }
                } else {
                    this.subscribeList = symbol;
                }
            }
        },
        subscribeSymbols(symbols) {
            this.changeSymbol(symbols, false);
        },
        initSnapshot(symbols) {
            if (this.initialized && this.mktdata) {
                this._setDefaultData(symbols);
                return this.mktdata
                    .quotes({
                        skipIdService: { 20: true },
                        securities: symbols
                    })
                    .then(res => {
                        res.forEach(item => {
                            this._setDataModel(item.security, item.quotes);
                        });
                    })
                    .catch(e => {
                        this._setError(this.ERROR_TYPES.NODATA);
                    });
            } else {
                return Promise.reject();
            }
        },
        _setDefaultData(symbols) {
            let defaultData = {};
            this.allDataPoints.forEach(dp => {
                defaultData[dp] = null;
            });
            symbols.forEach(symbol => {
                if (!this.dataModel[symbol]) {
                    this.$set(this.dataModel, symbol, {
                        id: symbol
                    });
                }
            });
        },
        updateData(security, updates) {
            this.throttleUpdateData(security, updates, this._setDataModel, 500);
        },
        _subscribe(dataType, symbols) {
            if (dataType && symbols && this[`${dataType}SubscribePromise`]) {
                this.isSubscribing = true;
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(symbols, this.subscribeListener, {
                            updateLastAccessTime: this.settings
                                .updateLastAccessTime,
                            skipIdService: { 20: true }
                        });
                    })
                    .catch(e => {});
            }
        },
        _unsubscribe(dataType, symbols) {
            if (dataType && symbols && this[`${dataType}SubscribePromise`]) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(
                            symbols,
                            this.subscribeListener,
                            {
                                skipIdService: { 20: true }
                            }
                        );
                    })
                    .catch(e => {});
            }
        },
        _setDataModel(securities, updates) {
            if (!utils.isArray(securities)) {
                securities = [securities];
                updates = [updates];
            }
            this.showLoading = false;
            const _dataModel = {};
            securities.forEach((security, index) => {
                const dataObject = {};
                const _update = updates[index];
                _update.forEach(item => {
                    if (
                        utils.inArray(this.allDataPoints, item.name) > -1 ||
                        item.name === 'closePrice'
                    ) {
                        if (
                            utils.inArray(this.numberDataPoints, item.name) > -1
                        ) {
                            dataObject[item.name] = +item.value;
                        } else {
                            dataObject[item.name] = item.value;
                        }
                    }
                });
                this.transFormChgData(dataObject, security);
                _dataModel[security] = dataObject;
            });
            this.dataModel = utils.extend(
                true,
                {},
                this.dataModel || {},
                _dataModel
            );
        },
        // calculate 'chg' and 'chg%' use askPrice or bidPrice to operation
        /**
         * when dataType is stream, backend not back lastPrice, we use askPrice or bidPrice depending on the monitor,
         * monitor default BidPrice, we use bidPrice - closePrice  = chg; (bidPrice - closePrice)/closePrice  = chg%,
         * monitor is AskPrice, we use askPrice - closePrice = chg; (askPrice - closePrice)/closePrice  = chg%;
         */
        transFormChgData(dataObject, security) {
            const closePrice =
                Number(dataObject.closePrice) ||
                this.dataModel[security].closePrice;
            const askPrice = dataObject.askPrice;
            const bidPrice = dataObject.bidPrice;
            const chg = !dataObject.chg;
            if (
                this.settings.monitor === 'AskPrice' &&
                askPrice &&
                chg &&
                closePrice > 0
            ) {
                dataObject['chg'] = askPrice - closePrice;
                dataObject['chg%'] =
                    ((askPrice - closePrice) * 100) / closePrice;
            } else if (
                this.settings.monitor === 'BidPrice' &&
                bidPrice &&
                chg &&
                closePrice > 0
            ) {
                dataObject['chg'] = bidPrice - closePrice;
                dataObject['chg%'] =
                    ((bidPrice - closePrice) * 100) / closePrice;
            }
        },
        _setError(error) {
            this.error = error;
            this.showLoading = false;
        }
    }
};
</script>
<style lang="scss"></style>
