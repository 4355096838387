import { isFunction } from './lang';

const GFS = {
    isJSRequestSendOut: {},
    isJSResponseReceive: {},
    success: function(src) {
        while (GFS.isJSRequestSendOut[src].length) {
            const callbacks = GFS.isJSRequestSendOut[src].pop();
            callbacks.success();
        }
    },
    error: function(src) {
        while (GFS.isJSRequestSendOut[src].length) {
            const callbacks = GFS.isJSRequestSendOut[src].pop();
            callbacks.error();
        }
    },
    noop: function() {}
};

export function loadJSScript(src, success, error) {
    success = isFunction(success) ? success : GFS.noop;
    error = isFunction(error) ? error : GFS.noop;
    if (GFS.isJSResponseReceive[src]) {
        success();
    } else {
        GFS.isJSRequestSendOut[src] = GFS.isJSRequestSendOut[src] || [];
        if (!GFS.isJSRequestSendOut[src].length) {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            script.src = src;
            script.async = false;
            script.onload = script.onreadystatechange = function() {
                if (
                    !this.readyState ||
                    this.readyState === 'loaded' ||
                    this.readyState === 'complete'
                ) {
                    GFS.isJSResponseReceive[src] = true;
                    GFS.success(src);
                }
            };
            script.onError = function() {
                head.removeChild(script);
                GFS.error(src);
            };
            head.appendChild(script);
        }
        GFS.isJSRequestSendOut[src].push({
            success,
            error
        });
    }
}

export function isJSResponseReceive(src) {
    return GFS.isJSResponseReceive[src];
}
