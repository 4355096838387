<template>
    <div :class="classes">
        <table :class="namespace('table')">
            <caption :class="namespace('table__caption')">
                {{
                    labels.forexCrossRatesCaption
                }}
            </caption>
            <thead>
                <tr
                    :class="namespace('table-header')"
                    v-if="forexDataModel.length"
                >
                    <th
                        scope="col"
                        v-for="(data, key) in headerData"
                        :key="key"
                        :class="namespace('header-item')"
                    >
                        <div :class="namespace('table-header-flex')">
                            <span
                                :class="[data.class, namespace('flag')]"
                            ></span>
                            <span :class="namespace('currency-label')">
                                {{ data.value }}
                            </span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody :class="namespace('table-body')" ref="tableBody">
                <tr v-for="(row, key) in forexDataModel" :key="key">
                    <th scope="col" :class="[namespace('header-item')]">
                        <div :class="namespace('table-header-flex')">
                            <div :class="[namespace('flag-wrapper')]">
                                <span
                                    :class="[
                                        row.header.class,
                                        namespace('flag')
                                    ]"
                                ></span>
                            </div>
                            <span>{{ row.header.value }}</span>
                        </div>
                    </th>
                    <td
                        v-for="(tdData, symbol) in row.data"
                        :key="symbol"
                        :data-symbol="symbol"
                        :class="[namespace('td-item'), ...tdData.class]"
                    >
                        <span :class="namespace('td-item-span')">
                            <span>{{ tdData.value }}</span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div :class="namespace('forex-legend')" v-if="forexDataModel.length">
            <span
                v-for="item in ranges"
                :key="item.id"
                :class="namespace(item.id)"
            ></span>
        </div>
        <markets-ui-popover
            :class="namespace('popover')"
            :skin="skin"
            :width="popoverWidth"
            :visible="popover.visible"
            :triggered-by="popover.triggeredId"
            :position="popover.position"
        >
            <div :class="namespace('tip-top')">
                <span :class="namespace('tip-currency-name')">
                    {{ popover.data.name }}
                </span>
                <span
                    :class="[
                        namespace('tip-currency-value'),
                        popover.data.chgClass
                    ]"
                    >{{ popover.data.chg }}</span
                >
            </div>
            <div :class="namespace('tip-middle')">
                <span :class="namespace('tip-date-time')"
                    >{{ labels.asof }} {{ popover.data.dateTime }}</span
                >
            </div>
            <div :class="namespace('tip-bottom')">
                <span :class="namespace('tip-rate-label')">
                    {{ labels.currentRate }}
                </span>
                <span :class="namespace('tip-rate-value')">
                    {{ popover.data.value }}
                </span>
            </div>
        </markets-ui-popover>
    </div>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import forexBasicMixin from './mixin';
import {
    getSymbolByCurrencies,
    getRTSymbol,
    CRYPTO_CURRENCY_ALIAS
} from '../common';
const { utils } = mwcMarketsCore;
export default {
    name: 'mwc-markets-forex-cross-rates',
    mixins: [forexBasicMixin],
    props: {
        horizonCurrencySet: {
            type: Array,
            default() {
                return [];
            }
        },
        verticalCurrencySet: {
            type: Array,
            default() {
                return [];
            }
        },
        monitor: {
            type: String,
            default: ''
        },
        contributorCode: {
            type: String,
            default: 'COMP'
        },
        popoverWidth: {
            type: Number,
            default: 200
        }
    },
    data() {
        return {
            width: null,
            popover: {
                visible: false,
                triggeredId: null,
                data: {},
                position: [
                    'right-center',
                    'top-right',
                    'top-center',
                    'bottom-center',
                    'bottom-right',
                    'bottom-left',
                    'left-center',
                    'top-left'
                ]
            }
        };
    },
    computed: {
        classes() {
            const cls = [
                this.namespace(),
                this.namespace(`${this.horizonCurrencySet.length}`)
            ];
            if (this.width && this.$el) {
                const show = this.values.every(value => {
                    return this.isShowValue(value);
                });
                const columnWidth =
                    (this.width - this.headerSize.width) /
                        this.horizonCurrencySet.length -
                    2;
                if (!show) {
                    cls.push(this.namespace('value-hidden'));
                }

                if (columnWidth >= this.headerSize.width) {
                    cls.push(this.namespace('flag-show'));
                }
            }

            return cls;
        },
        popoverData() {
            if (this.popover.visible) {
                return this.getDataBySymbol(
                    this.forexDataModel,
                    this.popover.symbol
                );
            } else {
                return null;
            }
        },
        headerData() {
            const header = [
                {
                    id: this.EMPTY,
                    value: ''
                }
            ];
            this.horizonCurrencySet.forEach(curr => {
                header.push({
                    id: curr,
                    value: CRYPTO_CURRENCY_ALIAS[curr] || curr,
                    class: this.namespace(`currency--${curr}`)
                });
            });
            return header;
        },
        values() {
            const values = [];
            for (const forex in this.dataModel) {
                const data = this.getDataFieldsByMonitor(
                    this.monitor,
                    this.dataModel[forex]
                );
                if (data.value) {
                    values.push(data.value);
                }
            }
            return values;
        },
        symbols() {
            return getSymbolByCurrencies(
                this.verticalCurrencySet,
                this.horizonCurrencySet,
                this.contributorCode
            );
        },
        forexDataModel() {
            const dataMap = {};
            this.verticalCurrencySet.forEach(cur1 => {
                dataMap[cur1] = {
                    header: {
                        id: cur1,
                        value: CRYPTO_CURRENCY_ALIAS[cur1] || cur1,
                        class: this.namespace(`currency--${cur1}`)
                    },
                    data: {}
                };
                const dt = new Date();
                const date = `${dt.getFullYear()}${utils.prefixZero(
                    dt.getMonth() + 1
                )}${utils.prefixZero(dt.getDate())}`;
                const time = `${dt.getHours()}:${utils.prefixZero(
                    dt.getMinutes()
                )}`;
                this.horizonCurrencySet.forEach(cur2 => {
                    const key = getRTSymbol(cur1 + cur2, this.contributorCode);
                    const defaultValue = this.getDefaultValue(cur1, cur2);
                    dataMap[cur1].data[key] = {
                        id: key,
                        name: `${CRYPTO_CURRENCY_ALIAS[cur1] || cur1}/${cur2}`,
                        value: defaultValue,
                        chg: 0,
                        date,
                        time,
                        class: this.getColorClass(0)
                    };
                });
            });

            this.symbols.forEach(symbol => {
                const currencyData = this.dataModel[symbol];
                if (currencyData) {
                    const currency = symbol.match(/.{7}(.{3}).+/)[1];
                    const dataItem = dataMap[currency].data;
                    utils.extend(
                        true,
                        dataItem[symbol],
                        {
                            id: symbol,
                            chg: currencyData['chg'],
                            class: this.getColorClass(currencyData['chg%'])
                        },
                        this.getDataFieldsByMonitor(this.monitor, currencyData)
                    );
                }
            });
            return Object.values(dataMap);
        }
    },

    watch: {
        popoverData: {
            handler(value) {
                if (value) {
                    this.popover.data = utils.extend(true, {}, value);
                }
            },
            deep: true
        }
    },

    created() {
        this.namespace = utils.namespace('forex-cross-rates');
        this.EMPTY = '';
        this.DEFAULT = '—';
        this.ranges = [
            {
                id: 'negative-100',
                range: [Number.NEGATIVE_INFINITY, -1.25]
            },
            {
                id: 'negative-60',
                range: [-1.25, -0.625]
            },
            {
                id: 'negative-30',
                range: [-0.625, -0.0001]
            },
            {
                id: 'neutral',
                range: [-0.0001, 0.0001]
            },
            {
                id: 'positive-30',
                range: [0.001, 0.625]
            },
            {
                id: 'positive-60',
                range: [0.625, 1.25]
            },
            {
                id: 'positive-100',
                range: [1.25, Number.POSITIVE_INFINITY]
            }
        ];
        this.initFormatter();
    },
    mounted() {
        this.$nextTick(() => {
            utils.resizeObserver.observe(
                this.$el,
                this._resizeObserverCallback
            );
            this.bindMouseEvents();
            this.headerSize = this._getHeaderSize();
        });
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this._resizeObserverCallback);
        this.popover.visible = false;
        document.removeEventListener('mouseout', this.onMouseLeave);
        const el = this.$refs.tableBody;
        if (el) {
            el.removeEventListener('mousemove', this.onMouseMove);
        }
        document.removeEventListener('mousemove', this.onMouseMove);
    },
    methods: {
        _resizeObserverCallback({ entry }) {
            this.width = entry.contentRect.width;
        },
        initFormatter() {
            const numberFormat = new Intl.NumberFormat(
                this.formatter.languageId,
                this.formatter.formats.number
            ).format;
            const dateFormat = new Intl.DateTimeFormat(
                this.formatter.languageId,
                this.formatter.formats.date
            ).format;
            const timeFormat = new Intl.DateTimeFormat(
                this.formatter.languageId,
                this.formatter.formats.time
            ).format;
            this._formatter = {
                number: numberFormat,
                date: dateFormat,
                time: timeFormat
            };
        },
        bindMouseEvents() {
            const el = this.$refs.tableBody;
            if (el) {
                el.addEventListener('mousemove', this.onMouseMove);
                el.addEventListener('mouseout', this.onMouseLeave);
            }
            document.addEventListener('mouseout', this.onMouseLeave);
            document.addEventListener('mousemove', this.onMouseMove);
        },
        onMouseLeave(e) {
            const toEle = e.relatedTarget || e.toElement || {};
            if (this._inPopover(toEle)) {
                return;
            }
            this.popover.visible = false;
        },
        _inPopover(elem) {
            while (elem) {
                if (
                    elem.classList &&
                    elem.classList.contains(this.namespace('popover'))
                ) {
                    return true;
                }
                elem = elem.parentNode;
            }
            return false;
        },
        onMouseMove(e) {
            let elem = e.target;
            const closestTd = utils.closest(
                elem,
                this.namespace('td-item'),
                true
            );
            if (closestTd) {
                elem = closestTd.querySelector(
                    `.${this.namespace('td-item-span')}`
                );
            }
            if (elem && this.$el.contains(elem)) {
                const symbol = elem.parentNode.getAttribute('data-symbol');
                if (!this.isValidForex(symbol)) {
                    return;
                }
                if (symbol && symbol !== this.popover.symbol) {
                    this.popover.symbol = symbol;
                    this.popover.data = this.getDataBySymbol(
                        this.forexDataModel,
                        symbol
                    );
                    this.popover.triggeredId = utils.computeTriggerId(elem);
                }
                this.popover.visible = true;
            }
        },
        isValidForex(str) {
            str = str && str.split('.')[2];
            if (str && str.substring(0, 3) !== str.substring(3, 6)) {
                return true;
            }
            return false;
        },
        getDataBySymbol(dataModel, symbol) {
            let data = {};
            const target = utils.find(dataModel, item => {
                return !!item.data[symbol];
            });
            const symbolData = target && target.data[symbol];
            if (symbolData) {
                data = utils.extend(true, {}, symbolData);
                data.chg = this._formatter.number(symbolData.chg);
                const date = utils.getFormatValue({
                    dataType: 'date',
                    formatter: this._formatter,
                    value: symbolData.date
                });
                const time = utils.getFormatValue({
                    dataType: 'time',
                    formatter: this._formatter,
                    value: symbolData.time
                });
                data.dateTime = `${date} ${time}`;
                if (symbolData.chg > 0) {
                    data.chgClass = this.namespace('tip-chg-positive');
                } else if (
                    symbolData.chg < 0 ||
                    (symbolData.chg + '').indexOf('-') > -1
                ) {
                    data.chgClass = this.namespace('tip-chg-negative');
                }
            }
            return data;
        },
        getDataFieldsByMonitor(monitor, dataObject) {
            const data = {};
            if (monitor === 'AskPrice') {
                data.value = dataObject.askPrice
                    ? this._formatter.number(dataObject.askPrice)
                    : undefined;
                data.time = dataObject.askTime || undefined;
                data.date = dataObject.askDate || undefined;
            } else {
                data.value = dataObject.bidPrice
                    ? this._formatter.number(dataObject.bidPrice)
                    : undefined;
                data.time = dataObject.bidTime || undefined;
                data.date = dataObject.bidDate || undefined;
            }
            return data;
        },
        getDefaultValue(cur1, cur2) {
            let value = this.EMPTY;
            if (cur1 === cur2) {
                value = this._formatter.number(1);
            } else {
                value = this.DEFAULT;
            }
            return value;
        },
        getColorClass(value) {
            for (const item of this.ranges) {
                if (value <= item.range[1] && value >= item.range[0]) {
                    return this.namespace(item.id);
                }
            }
            return this.namespace('neutral');
        },
        isShowValue(value) {
            let showFlag = true;
            const tdItem = this.$el.querySelector(
                `.${this.namespace('td-item')}`
            );
            if (!tdItem) {
                return showFlag;
            }
            const minWidth =
                (this.width - 50) / this.horizonCurrencySet.length - 2;
            tdItem.classList.add(this.namespace('relative'));
            const tempEl = document.createElement('span');
            const text = document.createTextNode(value);
            tempEl.style.position = 'absolute';
            tempEl.style.visibility = 'hidden';
            tempEl.appendChild(text);
            tdItem.appendChild(tempEl);
            if (tempEl.clientWidth >= minWidth) {
                showFlag = false;
            }
            tdItem.classList.remove(this.namespace('relative'));
            tdItem.removeChild(tempEl);
            return showFlag;
        },
        _getHeaderSize() {
            this.$el.classList.add(this.namespace('flag-show'));
            const rowHeaderEl = this.$el.querySelector(
                `.${this.namespace('table-body')} .${this.namespace(
                    'header-item'
                )}`
            );
            const columnHeaderEl = this.$el.querySelector(
                `.${this.namespace('table-header')}`
            );
            const size = {
                width: rowHeaderEl.clientWidth,
                height: columnHeaderEl.clientHeight
            };
            this.$el.classList.remove(this.namespace('flag-show'));
            return size;
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
@import '@mds/utils-scss';
$namespace: 'mwc-markets-forex-cross-rates';
$base: $mds-visualization-color-performance-neutral;
$basePositive: $mds-visualization-color-performance-positive;
$baseNegative: $mds-visualization-color-performance-negative;

@function getColor($color, $percentage) {
    @return mix($color, #ffffff, $percentage);
}

.markets-ui-container__fixed-height {
    .#{$namespace} {
        height: 100%;
        &-table {
            height: calc(100% - 65px);
        }
    }
}

.#{$namespace} {
    padding-bottom: 1px;
    color: $mds-text-color-primary;
    * {
        box-sizing: border-box;
    }
    &-table-body {
        @for $i from 1 through 23 {
            // only support 23 currencies at most
            &-#{$i} tr {
                height: calc(100% / #{$i});
            }
        }
    }

    &-relative {
        position: relative;
    }
    &-value-hidden {
        .#{$namespace}-td-item .#{$namespace}-td-item-span span {
            display: none;
        }
    }
    &-table {
        width: 100%;
        min-height: 250px;
        table-layout: fixed;
        border-spacing: 0px;
        td,
        th {
            border-bottom: 0;
            border-left: 0;
        }
        td:last-child,
        th:last-child {
            border-right: 0;
        }
        &__caption {
            // @include mds-accessibly-hidden;
            // why use display:none instead: the accessibly hidden will impact the table height responsive
            display: none;
        }
    }
    &-table-header {
        height: 50px;
    }
    &-header-item {
        font-size: $mds-typography-font-size-s;
        font-weight: $mds-typography-font-weight-regular;
    }
    &-table-header-flex {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-height: 38px;
    }
    &-td-item {
        font-size: $mds-typography-font-size-s;
        font-weight: $mds-typography-font-weight-bold;
        border: solid 1px $mds-color-white;
        text-align: center;
        cursor: default;
    }
    &-forex-legend {
        width: calc((100% - 50px) * 0.7);
        height: 10px;
        min-width: 135px;
        max-width: 320px;
        display: flex;
        border-radius: 8px;
        position: relative;
        margin: 20px auto 35px auto;
        & > span {
            height: 100%;
            flex: 1;
            &:first-child {
                border-radius: 5px 0px 0px 5px;
            }
            &:last-child {
                border-radius: 0px 5px 5px 0px;
            }
        }
        .#{$namespace} {
            &-positive-100 {
                &:before {
                    content: '1.25% ≥';
                    font-size: 13px;
                    position: absolute;
                    top: 15px;
                    white-space: nowrap;
                }
            }
            &-negative-100 {
                &:before {
                    content: '≤ –1.25%';
                    font-size: 13px;
                    position: absolute;
                    top: 15px;
                    white-space: nowrap;
                }
            }
            &-neutral {
                &:before {
                    position: absolute;
                    content: '0';
                    left: calc(50% - 3px);
                    top: 15px;
                    font-size: 13px;
                    white-space: nowrap;
                }
            }
        }
    }
    &-header-item {
        &:first-child {
            width: 50px;
        }
    }
    &-positive {
        &-30 {
            background-color: getColor($basePositive, 30%);
        }
        &-60 {
            background-color: getColor($basePositive, 60%);
        }
        &-100 {
            background-color: $basePositive;
        }
    }
    &-negative {
        &-30 {
            background-color: getColor($baseNegative, 30%);
        }
        &-60 {
            background-color: getColor($baseNegative, 60%);
        }
        &-100 {
            background-color: $baseNegative;
        }
    }
    &-neutral {
        background-color: $base;
    }

    /*popover-tip*/
    &-tip {
        &-top,
        &-bottom {
            display: flex;
            justify-content: space-between;
            font-weight: $mds-typography-font-weight-bold;
        }
        &-middle {
            padding: 8px 0px 6px 0px;
            border-bottom: solid 2px;
            margin-bottom: 12px;
        }

        &-currency-name {
            flex-basis: 70px;
        }
        &-rate-label {
            font-weight: $mds-typography-font-weight-regular;
            flex-basis: 150px;
        }
        &-date-time {
            font-size: 12px;
            color: $mds-text-color-secondary;
        }
        &-chg {
            &-positive {
                color: $mds-text-color-performance-positive;
            }
            &-negative {
                color: $mds-text-color-performance-negative;
            }
        }
    }
}

$currency: USD GBP EUR JPY CAD CHF CNY BRL CLP CNY COP CUP CZK INR IDR IRR IQD
    LBP MXN NZD PHP SGD;

@each $cur in $currency {
    .#{$namespace}-flag-show {
        .#{$namespace}-currency--#{$cur} {
            background: url('../assets/images/#{$cur}.png') no-repeat;
        }
        .#{$namespace} {
            &-header-item {
                &:first-child {
                    width: 80px;
                }
            }
        }
    }
}
.#{$namespace}-flag-show {
    .#{$namespace}-flag {
        &-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 38px;
            height: 38px;
        }
        background-position: center;
        margin-right: 10px;
        height: 20px;
        width: 38px;
        display: inline-block;
    }
}
.#{$namespace}-flag-show .#{$namespace}-currency--JPY {
    border: solid 1px #ccc;
}

.markets-ui-container__dark-gray {
    .#{$namespace}-table-header,
    .#{$namespace}-header-item,
    .#{$namespace}-negative-100:before,
    .#{$namespace}-positive-100:before,
    .#{$namespace}-neutral:before {
        color: $mds-text-color-primary-on-dark;
    }
    .#{$namespace} {
        &-td-item {
            border-color: $mds-background-color-dark-gray;
        }
    }
}
.markets-ui-popover__dark-gray .#{$namespace}-tip-date-time {
    color: $mds-text-color-primary-on-dark;
}
</style>
