var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { class: [_vm.namespace(), _vm.skinStyle] },
    [
      _c(
        "markets-ui-list",
        {
          class: _vm.namespace("dragable-container"),
          attrs: { skin: _vm.skin, "data-model": _vm.dataList, "with-icon": "" }
        },
        [
          _c("markets-ui-button", {
            class: _vm.namespace("remove-icon"),
            attrs: {
              slot: "mds-list-item-right",
              icon: "remove",
              variation: "icon-only",
              skin: _vm.skin,
              text: _vm.labels.remove
            },
            on: { click: _vm.onRemove },
            slot: "mds-list-item-right"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "footer",
        { class: [_vm.namespace("__footer")] },
        [_vm._t("footer")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }