<template>
    <div :class="namespace()">
        <div :class="namespace('grid-container')">
            <markets-ui-grid
                :skin="skin"
                :groupKey="groupKey"
                :formatter="formatter"
                :rows="rows"
                :columns="columns"
                :option="settings"
                :labels="labels"
                :caption="labels.forexTableCaption"
                @visible-rows-update="visibleRowsUpdate"
            ></markets-ui-grid>
        </div>
    </div>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import { getDataPointsByIds } from '../metadata/data-point';
import forexBasicMixin from './mixin';
import { CRYPTO_CURRENCY_ALIAS } from '../common';
const { utils } = mwcMarketsCore;
export default {
    name: 'mwc-markets-forex-table',
    mixins: [forexBasicMixin],
    props: {
        isCryptocurrency: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        rows() {
            const rows = [];
            for (const security in this.dataModel) {
                const securityData = {},
                    dataObject = this.dataModel[security];
                let currencyPair = [];
                this.columns.forEach(col => {
                    switch (col.id) {
                        case 'name': {
                            currencyPair = security
                                .replace(/.{7}(.{3})(.{3}).{4}/, '$1:$2')
                                .split(':');
                            securityData[col.id] = currencyPair
                                .map(c => CRYPTO_CURRENCY_ALIAS[c] || c)
                                .join(':');
                            break;
                        }
                        case 'symbol': {
                            securityData[col.id] = security;
                            break;
                        }
                        default: {
                            securityData[col.id] = dataObject[col.id];
                            break;
                        }
                    }
                });
                securityData.id = dataObject.id;
                securityData.currency = this.isCryptocurrency
                    ? this.labels[currencyPair[0]]
                    : currencyPair[0];
                if (securityData.bidPrice || securityData.askPrice) {
                    // only show rows which have bid or ask
                    rows.push(securityData);
                }
            }
            return this.sort(rows);
        },
        columns() {
            if (this.settings.dataPoints) {
                const dataPoints = getDataPointsByIds(
                    this.settings.dataPoints.split(',')
                );
                return dataPoints.map(dp => {
                    return utils.extend(true, dp, {
                        name: this.labels[dp.id] || dp.id,
                        sortable: this.settings.sortable
                    });
                });
            } else {
                return [];
            }
        },
        groupKey() {
            if (this.settings.groupKey === 'noGrouping') {
                return '';
            } else {
                return this.settings.groupKey;
            }
        }
    },
    created() {
        this.namespace = utils.namespace('forex-table');
    },
    methods: {
        visibleRowsUpdate(obj) {
            if (obj.visibleRows.length) {
                const symbols = obj.visibleRows.map(item => {
                    return item.id;
                });
                this.$emit('visible-rows-update', symbols);
            }
        },
        sort(rows) {
            if (this.settings.groupKey !== 'noGrouping') {
                // order by alphabet
                return rows.sort((a, b) => {
                    if (a.currency < b.currency) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
            } else {
                //These forex need to be at the top of the watchlist
                const vipExchanges = [
                    'USD:EUR',
                    'USD:JPY',
                    'EUR:USD',
                    'GBP:USD',
                    'USD:CHF',
                    'USD:CAD',
                    'AUD:USD',
                    'NZD:USD'
                ];
                return rows.sort((a, b) => {
                    const indexA = utils.inArray(vipExchanges, a.name);
                    const indexB = utils.inArray(vipExchanges, b.name);
                    if (
                        (indexA < indexB && indexA > -1) ||
                        (indexB < indexA && indexB > -1)
                    ) {
                        // both in vip list, order by index
                        if (indexA < indexB) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else if (indexA === indexB && indexB === -1) {
                        // neither in vip list, order by alphabet
                        if (a.name < b.name) {
                            return -1;
                        } else {
                            return 1;
                        }
                    } else {
                        // either A or B in vip list
                        if (indexA > -1) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss">
.markets-ui-container__fixed-height {
    .mwc-markets-forex-table {
        height: 100%;
        &-grid-container {
            height: 100%;
        }
    }
}
</style>
