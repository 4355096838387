<template>
    <div :class="classes" :id="namespace()">
        <div
            :class="namespace('item')"
            :id="namespace('item-' + data.id)"
            v-for="(data, index) in boardDataModel"
            :key="index"
        >
            <div
                v-if="isCryptocurrency"
                :class="[
                    namespace('currency-icon'),
                    namespace('currency--' + data.id.substring(0, 3)),
                    namespace(
                        isSmallSize
                            ? 'currency-icon-small'
                            : 'currency-icon-normal'
                    )
                ]"
            ></div>
            <div
                :class="[
                    namespace('item-currency'),
                    isSmallSize ? '' : namespace('item-currency-align')
                ]"
            >
                <span>{{ data.name }}</span>
                <markets-ui-button
                    v-if="!isCryptocurrency"
                    variation="icon-only"
                    :skin="skin"
                    icon="triangle-fill-down"
                    :text="labels.addCurrency"
                    @click="showCurrencyPopover($event, data.id)"
                />
            </div>
            <div :class="namespace('item-bid')">
                <div :class="namespace('item-price-top')">
                    {{ data.bidPrice.top }}
                </div>
                <div :class="namespace('item-price-bottom')">
                    <span :class="namespace('item-price-bottom--large')">{{
                        data.bidPrice.bottomL
                    }}</span>
                    <span :class="namespace('item-price-bottom--small')">{{
                        data.bidPrice.bottomS
                    }}</span>
                </div>
                <div :class="namespace('item-up-down')"></div>
            </div>
            <div :class="namespace('item-spread')" v-if="!isSmallSize">
                {{ data.spread }}
            </div>
            <div :class="namespace('item-ask')">
                <div :class="namespace('item-price-top')">
                    {{ data.askPrice.top }}
                </div>
                <div :class="namespace('item-price-bottom')">
                    <span :class="namespace('item-price-bottom--large')">{{
                        data.askPrice.bottomL
                    }}</span>
                    <span :class="namespace('item-price-bottom--small')">{{
                        data.askPrice.bottomS
                    }}</span>
                </div>
                <div :class="namespace('item-up-down')"></div>
            </div>
            <div :class="namespace('item-trades')" v-if="!isSmallSize">
                {{ data.trades }}
            </div>
            <div
                :class="[
                    namespace('item-change-p'),
                    namespace('__' + data.upDown)
                ]"
            >
                {{ data.changeP }}
            </div>
            <div :class="namespace('item-time')">{{ data.time }}</div>
        </div>
        <markets-ui-settings
            :skin="skin"
            :triggered-by="popoverTriggerId"
            :showSearchField="popoverShowSearch"
            :visible="popoverVisible"
            :width="popoverWidth"
            :priority="popoverPriority"
            :positionPriority="popoverPositionPriority"
            :directionPriority="popoverDirectionPriority"
            :class="namespace('popover')"
            :title="popoverTitle"
            :searchFieldPlaceholder="searchFieldPlaceholder"
            @hide="hideSettingsPopover"
            @search-key-changed="filterCurrencies"
        >
            <markets-ui-list
                slot="content"
                :skin="skin"
                :dataModel="currencyOptions"
                @change="exchangeCurrency"
            ></markets-ui-list>
        </markets-ui-settings>
    </div>
</template>
<script>
import Sortable from 'sortablejs';
import mwcMarketsCore from 'mwc-markets-core';
import forexBasicMixin from './mixin';
import {
    getRTSymbol,
    getCurrencyLabel,
    getCurrencyPairByCurrencies,
    matchedKeywords
} from '../common';
const { utils } = mwcMarketsCore;
export default {
    name: 'mwc-markets-forex-board',
    mixins: [forexBasicMixin],
    props: {
        flashDoms: {
            type: Array,
            default() {
                return ['changeP'];
            }
        },
        currencySet: {
            type: Array,
            default() {
                return [];
            }
        },
        selectedCurrencySet: {
            type: Array,
            default() {
                return [];
            }
        },
        contributorCode: {
            type: String,
            default: 'COMP'
        },
        breakpoints: {
            type: Array,
            default() {
                return [800, 1200];
            }
        },
        isCryptocurrency: {
            type: Boolean,
            default: false
        },
        searchFieldPlaceholder: {
            type: String,
            default: 'Search'
        }
    },
    data() {
        return {
            width: null,
            clickedCurrency: null,
            checkedCurrency: null,
            currenciesOnBoard: null,
            popoverTriggerId: null,
            popoverVisible: false,
            popoverShowSearch: true,
            popoverPriority: ['bottom', 'top', 'right', 'left'],
            popoverSearchKey: '',
            popoverPositionPriority: {
                bottom: ['center', 'right', 'left'],
                top: ['center', 'right', 'left'],
                right: ['bottom', 'center', 'top'],
                left: ['bottom', 'center', 'top']
            },
            popoverDirectionPriority: {
                type: Object,
                default: () => {
                    return {
                        bottom: ['center', 'left', 'right'],
                        top: ['center', 'left', 'right'],
                        right: ['bottom', 'center', 'left'],
                        left: ['bottom', 'center', 'right']
                    };
                }
            }
        };
    },
    computed: {
        isSmallSize() {
            return this.width <= 400;
        },
        classes() {
            const cls = [this.namespace()];
            if (this.width) {
                const breakIndex = this.getBreakpointsIndex(
                    this.width,
                    this.breakpoints
                );
                if (breakIndex > -1) {
                    for (let i = breakIndex; i < this.breakpoints.length; i++) {
                        cls.push(`${this.namespace(this.breakpoints[i])}`);
                    }
                }
            }
            return cls;
        },
        popoverTitle() {
            return this.labels['addCurrency'];
        },
        boardDataModel() {
            return this.selectedCurrencySet.map(c => {
                let name = c;
                if (this.isCryptocurrency && !this.isSmallSize) {
                    name = name.split('/');
                    name[0] = this.labels[name[0]] || name[0];
                    name = name.join('/');
                }
                const id = c.replace('/', '');
                const symbol = getRTSymbol(id, this.contributorCode);
                const data = this.dataModel[symbol] || {};
                const askPrice = this.getPriceData(data.askPrice);
                const bidPrice = this.getPriceData(data.bidPrice);
                const hasSpread = isNaN(data.spread) || !data.spread;
                const formatSpread = this.formatter.format(
                    Math.abs(data.spread),
                    'boardNumber'
                );
                const spread = hasSpread ? this.defaultValue : formatSpread;
                let upDown = 'neutral';
                if (data['chg%'] > 0) {
                    upDown = 'down';
                } else if (data['chg%'] < 0) {
                    upDown = 'up';
                }
                return {
                    name,
                    id,
                    bidPrice: this.getPriceData(data.bidPrice) || bidPrice,
                    askPrice: this.getPriceData(data.askPrice) || askPrice,
                    ask: askPrice.value,
                    bid: bidPrice.value,
                    spread: spread || this.defaultValue,
                    trades:
                        utils.getFormatValue({
                            formatter: this.formatter,
                            value: data.numberOfTradesSinceMarketOpen,
                            dataType: 'int'
                        }) || this.defaultValue,
                    changeP:
                        utils.getFormatValue({
                            formatter: this.formatter,
                            value: data['chg%'],
                            dataType: 'percent'
                        }) || this.defaultValue,
                    upDown,
                    time: data.askTime || this.defaultValue
                };
            });
        },
        allCurrencies() {
            return getCurrencyPairByCurrencies(this.currencySet, '/');
        },
        visibleCurrencies() {
            return this.allCurrencies.filter(c => {
                let flag = true;
                if (this.popoverSearchKey) {
                    flag = matchedKeywords(
                        c,
                        this.popoverSearchKey,
                        this.labels
                    );
                }
                return (
                    flag && utils.inArray(this.selectedCurrencySet, c) === -1
                );
            });
        },
        currencyOptions() {
            return this.visibleCurrencies.map(item => {
                return {
                    id: item,
                    selected: false,
                    name: getCurrencyLabel(item, this.labels),
                    metadataBelow: item
                };
            });
        }
    },
    created() {
        this.namespace = utils.namespace('forex-board');
        this.popoverWidth = 220;
        this.defaultValue = '—';
    },
    beforeDestroy() {
        utils.resizeObserver.unobserve(this.$el, this._resizeObserverCallback);
    },
    mounted() {
        utils.resizeObserver.observe(this.$el, this._resizeObserverCallback);
        this.initSorting(this.$el);
        this.width = utils.outerSizes(this.$el).width;
    },
    watch: {
        boardDataModel(newValue, oldValue) {
            this.findDifference(newValue, oldValue);
        }
    },
    methods: {
        _resizeObserverCallback({ entry }) {
            this.width = entry.contentRect.width;
        },
        initSorting(el) {
            Sortable.create(el);
        },

        hideSettingsPopover() {
            this.popoverVisible = false;
            this.clickedCurrency = null;
        },
        showCurrencyPopover(e, currentId) {
            this.clickedCurrency = `${currentId.substring(
                0,
                3
            )}/${currentId.substring(3, 6)}`;
            this.popoverTriggerId = utils.computeTriggerId(e.currentTarget);
            this.popoverVisible = true;
        },
        filterCurrencies(keyWord) {
            this.popoverSearchKey = keyWord;
        },
        matchedKeywords(currency, searchKey) {
            const [cur1, cur2] = currency.split('/');
            return (
                utils.findIndex(
                    [this.labels[cur1], this.labels[cur2], currency],
                    item =>
                        item.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
                ) > -1
            );
        },
        exchangeCurrency(selectedItem) {
            this.checkedCurrency = selectedItem.id;
            this.currenciesOnBoard = utils.extend(
                true,
                [],
                this.selectedCurrencySet
            );
            const idx = utils.inArray(
                this.currenciesOnBoard,
                this.clickedCurrency
            );
            this.currenciesOnBoard.splice(idx, 1, this.checkedCurrency);
            this.$emit('symbol-changed', this.currenciesOnBoard);
            this.hideSettingsPopover();
        },
        getBreakpointsIndex(width, breakpoints) {
            return utils.findIndex(breakpoints, bp => width <= bp);
        },
        getPriceData(price) {
            if (price) {
                price = price.toFixed(6);
                const priceData = price.split('.');
                priceData[1] = this.formatter.format(
                    `0.${priceData[1]}`,
                    'boardNumber'
                );
                priceData[1] = priceData[1].substring(2, priceData[1].length);
                return {
                    top: `${priceData[0]}.${priceData[1].substring(0, 2)}`,
                    bottomL: priceData[1].substring(2, 4),
                    bottomS: priceData[1].substring(4, 5),
                    value: price
                };
            } else {
                return {
                    top: this.defaultValue,
                    bottomL: this.defaultValue,
                    bottomS: this.defaultValue,
                    value: this.defaultValue
                };
            }
        },
        findDifference(newValue, oldValue) {
            newValue.forEach(newItem => {
                const id = newItem.id;
                const oldIndex = utils.inArray(oldValue, item => {
                    return item.id === id;
                });
                const oldItem = oldValue[oldIndex];
                const flashDoms = [];
                if (!oldItem) {
                    return;
                }
                this.flashDoms.forEach(dp => {
                    if (
                        newItem[dp] &&
                        oldItem[dp] &&
                        newItem[dp] !== oldItem[dp]
                    ) {
                        const idSelector = this.namespace(`item-${id}`);
                        const clsSelector = this.namespace(`item-${dp}`);
                        const selector = `#${idSelector} .${clsSelector}`;
                        const node = this.$el.querySelector(selector);
                        if (node) {
                            flashDoms.push({
                                node: node,
                                class: this.getFlashClass(
                                    newItem[dp],
                                    oldItem[dp]
                                )
                            });
                        }
                    }
                });
                flashDoms.forEach(dom => {
                    utils.flash(dom.node, dom.class);
                });
                ['ask', 'bid'].forEach(dp => {
                    if (newItem[dp] && oldItem[dp]) {
                        const idSelector = this.namespace(`item-${id}`);
                        const clsSelector = this.namespace(`item-${dp}`);
                        const node = this.$el.querySelector(
                            `#${idSelector} .${clsSelector} .${this.namespace(
                                'item-up-down'
                            )}`
                        );
                        const cls = this.getUpDownClass(
                            newItem[dp],
                            oldItem[dp]
                        );
                        if (cls && node) {
                            ['down', 'up', 'neutral'].forEach(cl => {
                                node.classList.remove(
                                    this.namespace(`__${cl}`)
                                );
                            });
                            node.classList.add(
                                this.getUpDownClass(newItem[dp], oldItem[dp])
                            );
                        }
                    }
                });
            });
        },
        getUpDownClass(value, oldValue) {
            let className = '';
            if (oldValue === this.defaultValue || value === this.defaultValue) {
                className = this.namespace('__neutral');
            } else {
                if (value > oldValue) {
                    className = this.namespace('__up');
                } else if (value < oldValue) {
                    className = this.namespace('__down');
                }
            }
            return className;
        },
        getFlashClass(newValue, oldValue) {
            let className = '';
            if (
                oldValue !== undefined &&
                newValue !== undefined &&
                newValue !== oldValue
            ) {
                className =
                    newValue > oldValue
                        ? this.namespace('flash__positive')
                        : this.namespace('flash__negative');
            }
            return className;
        }
    }
};
</script>
<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';
$namespace: 'mwc-markets-forex-board';
.markets-ui-container__fixed-height {
    .#{$namespace} {
        height: 100%;
    }
}

.#{$namespace} {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    font-size: $mds-typography-font-size-m;
    &-flash {
        &__positive {
            background-color: $mds-visualization-color-performance-positive;
        }
        &__negative {
            background-color: $mds-visualization-color-performance-negative;
        }
    }
    &-item-up-down {
        width: 0;
        height: 0;
        position: absolute;
        bottom: 6px;
        right: 0;
    }
    &__down {
        color: $mds-text-color-performance-negative;
        stroke: $mds-text-color-performance-negative;
        &.#{$namespace}-item-up-down {
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-top: 6px solid $mds-text-color-performance-negative;
        }
    }
    &__neutral {
        color: $mds-text-color-performance-neutral;
        stroke: $mds-text-color-performance-neutral;
        &.#{$namespace}-item-up-down {
            border-color: transparent;
        }
    }
    &__up {
        color: $mds-text-color-performance-positive;
        fill: $mds-text-color-performance-positive;
        &.#{$namespace}-item-up-down {
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            border-bottom: 6px solid $mds-text-color-performance-negative;
        }
    }

    &-item {
        width: calc((100% - 12px * 3) / 3);
        display: flex;
        justify-content: space-between;
        height: 60px;
        border: solid 1px $mds-color-neutral-50;
        margin: 6px 6px;
        align-items: center;
        text-align: center;
        padding: 6px;
        min-width: 320px;
        cursor: grab;
        span {
            cursor: default;
        }
        &-bid,
        &-ask {
            padding: 2px;
            min-width: 45px;
            position: relative;
        }
        &-spread {
            min-width: 50px;
            font-size: $mds-typography-font-size-s;
        }
        &-trades {
            min-width: 40px;
        }
        &-change-p {
            min-width: 50px;
        }
    }
    &-item-currency {
        display: flex;
        align-items: center;
    }
    &-item-price-top {
        font-size: 10px;
    }
    &-item-price-bottom--large {
        font-size: $mds-typography-font-size-l;
        font-weight: $mds-typography-font-weight-bold;
    }
    &-item-price-bottom--small {
        font-size: $mds-typography-font-size-s;
    }
    &.#{$namespace}-1200 {
        .#{$namespace}-item {
            width: calc((100% - 12px * 2) / 2);
        }
    }
    &.#{$namespace}-800 {
        .#{$namespace}-item {
            width: calc(100% - 12px);
        }
    }
    .#{$namespace}-dropdown-icon {
        fill: $mds-color-neutral-37;
        padding-top: 7px;
        cursor: pointer;
    }

    &-popover {
        width: 320px;
        .mds-list-group__item-text___markets {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
        }
        .markets-ui-list .mds-list-group__item-metadata-below___markets {
            padding: $mds-space-half-x;
        }
        .markets-ui-settings__content {
            max-height: 200px;
        }
        .mds-list-group__item-metadata-below-item___markets {
            font-weight: $mds-typography-font-weight-light;
        }
    }
}
.markets-ui-container__dark-gray {
    .#{$namespace} {
        .#{$namespace}-dropdown-icon {
            fill: $mds-color-neutral-67;
        }
        &__down {
            color: $mds-text-color-performance-negative-on-dark;
            fill: $mds-text-color-performance-negative-on-dark;
            &.#{$namespace}-item-up-down {
                border-top-color: $mds-text-color-performance-negative-on-dark;
            }
        }
        &__neutral {
            color: $mds-text-color-performance-neutral-on-dark;
            fill: $mds-text-color-performance-neutral-on-dark;
        }
        &__up {
            color: $mds-text-color-performance-positive-on-dark;
            fill: $mds-text-color-performance-positive-on-dark;
            &.#{$namespace}-item-up-down {
                border-bottom-color: $mds-text-color-performance-positive-on-dark;
            }
        }
        &-flash {
            &__positive {
                background-color: $mds-text-color-performance-positive-on-dark;
            }
            &__negative {
                background-color: $mds-text-color-performance-negative-on-dark;
            }
        }
    }
}
</style>
