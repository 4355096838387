import { extend } from './lang';
import { getPriceWithCurrency } from './currency';
export const emptyString = '—';
class Formatter {
    constructor(languageId, formats) {
        this.languageId = languageId || 'en-US';
        this.formats = extend(
            {},
            {
                date: {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                },
                time: {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                },
                number: {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                },
                integer: {
                    useGrouping: true,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                },
                percent: {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                },
                dateTime: {
                    dataType: 'date',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour12: true
                },
                nullString: emptyString
            },
            formats
        );
    }
    format(value, type, opts) {
        if (typeof value === 'undefined' || value === null) {
            return this.formats.nullString;
        }
        opts = opts || this.formats[type];
        let formattedValue;
        switch (type) {
            case 'number':
            case 'integer':
                formattedValue = Intl.NumberFormat(
                    this.languageId,
                    opts
                ).format(value);
                break;
            case 'date':
            case 'time':
            case 'dateTime':
                formattedValue = Intl.DateTimeFormat(
                    this.languageId,
                    opts
                ).format(value);
                break;
            default:
                formattedValue = value;
        }
        return formattedValue;
    }
    date(value, format) {
        return this.format(value, format || 'date');
    }
    number(value, format) {
        return this.format(value, format || 'number');
    }
}

function numberFormat(formatter, value) {
    let _value;
    if (value === void 0 || value === null || isNaN(value)) {
        _value = emptyString;
    } else {
        _value = formatter.number(value);
    }
    return _value;
}

function intFormat(formatter, value) {
    let _value;
    if (value === void 0 || value === null || isNaN(value)) {
        _value = emptyString;
    } else {
        _value = formatter.format(value, 'integer');
    }
    return _value;
}

function abbreviationFormat(formatter, value, isInteger) {
    let _value;
    const dataType = isInteger ? 'integer' : 'number';
    if (value === void 0 || value === null || isNaN(value)) {
        _value = emptyString;
    } else {
        _value = typeof value === 'string' ? +value : value;
        if (_value > 1000000000) {
            _value = `${formatter.format(+value / 1000000000, dataType)} Bil`;
        } else if (_value > 1000000) {
            _value = `${formatter.format(+value / 1000000, dataType)} Mil`;
        } else {
            _value = formatter.format(value, dataType);
        }
    }
    return _value;
}

function dateFormat(formatter, value) {
    let _value;
    if (value && /^(\d{4})-?(\d{2})-?(\d{2}).*$|\d{6}$/.test(value)) {
        const date = new Date(
            value.replace(/(\d{4})-?(\d{2})-?(\d{2}).*/, '$1-$2-$3T00:00:00')
        );
        if (!isNaN(date)) {
            _value = formatter.date(date);
        } else {
            _value = emptyString;
        }
    } else {
        _value = value || emptyString;
    }
    return _value;
}

function timeFormat(formatter, value) {
    if (value && /\d{2}:\d{2}.*/.test(value)) {
        const timeArr = value.split(':');
        const date = new Date();
        date.setHours(timeArr[0]);
        date.setMinutes(timeArr[1]);
        const time =
            typeof formatter.time === 'function'
                ? formatter.time(date)
                : formatter.format(date, 'time');
        return time.replace(/(.+,\s)(.+)/, '$2');
    } else {
        return value || emptyString;
    }
}
function withCurrencyFormat(formatter, value, currency) {
    if (!currency) {
        return numberFormat(formatter, value);
    }
    value = numberFormat(formatter, value);
    return getPriceWithCurrency(value, currency);
}
function percentFormat(formatter, value) {
    if (value) {
        return `${formatter.format(value, 'percent')}%`;
    } else {
        return value;
    }
}

function dateTimeFormat(formatter, value) {
    if (
        value &&
        /^(\d{4})-?(\d{2})-?(\d{2})(\s|T)(\d{2}:\d{2}:\d{2}).*/.test(value)
    ) {
        const date = value.replace(
            /^(\d{4})-?(\d{2})-?(\d{2})(\s|T)(\d{2}:\d{2}:\d{2}).*/,
            '$1-$2-$3T$5'
        );
        const dateTime = new Date(date);
        return formatter.format(dateTime, 'dateTime');
    } else {
        return value || emptyString;
    }
}

export function getFormatValue(obj) {
    let formatValue;
    if (obj.value === '') {
        return obj.value;
    }
    const formatter = obj.formatter || new Formatter(obj.languageId || 'en-US');

    switch (obj.dataType) {
        case 'number':
            formatValue = numberFormat(formatter, obj.value);
            break;
        case 'string':
            formatValue = obj.value || emptyString;
            break;
        case 'date':
            formatValue = dateFormat(formatter, obj.value);
            break;
        case 'time':
            formatValue = timeFormat(formatter, obj.value);
            break;
        case 'int':
            formatValue = intFormat(formatter, obj.value);
            break;
        case 'abbreviation':
            formatValue = abbreviationFormat(formatter, obj.value);
            break;
        case 'intAbbreviation':
            formatValue = abbreviationFormat(formatter, obj.value, true);
            break;
        case 'percent':
            formatValue = percentFormat(formatter, obj.value);
            break;
        case 'dateTime':
            formatValue = dateTimeFormat(formatter, obj.value);
            break;
        case 'withCurrency':
            formatValue = withCurrencyFormat(
                formatter,
                obj.value,
                obj.listedCurrency
            );
            break;
        default:
            formatValue = formatter.format(obj.value, obj.dataType);
            break;
    }
    return formatValue;
}

export function flash(cellNode, flashClass) {
    if (window.requestAnimationFrame) {
        let start = null;
        const flash = function(timestamp) {
            if (!start) {
                start = timestamp;
            }
            const time = timestamp - start;
            cellNode.classList.add(flashClass);
            if (time < 200) {
                window.requestAnimationFrame(flash);
            } else {
                cellNode.classList.remove(flashClass);
            }
        };
        window.requestAnimationFrame(flash);
    } else {
        cellNode.classList.add(flashClass);
        setTimeout(function() {
            cellNode.classList.remove(flashClass);
        }, 200);
    }
}
