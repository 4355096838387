var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("table", { class: _vm.namespace("table") }, [
        _c("caption", { class: _vm.namespace("table__caption") }, [
          _vm._v(
            "\n            " +
              _vm._s(_vm.labels.forexCrossRatesCaption) +
              "\n        "
          )
        ]),
        _vm._v(" "),
        _c("thead", [
          _vm.forexDataModel.length
            ? _c(
                "tr",
                { class: _vm.namespace("table-header") },
                _vm._l(_vm.headerData, function(data, key) {
                  return _c(
                    "th",
                    {
                      key: key,
                      class: _vm.namespace("header-item"),
                      attrs: { scope: "col" }
                    },
                    [
                      _c("div", { class: _vm.namespace("table-header-flex") }, [
                        _c("span", {
                          class: [data.class, _vm.namespace("flag")]
                        }),
                        _vm._v(" "),
                        _c("span", { class: _vm.namespace("currency-label") }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(data.value) +
                              "\n                        "
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          { ref: "tableBody", class: _vm.namespace("table-body") },
          _vm._l(_vm.forexDataModel, function(row, key) {
            return _c(
              "tr",
              { key: key },
              [
                _c(
                  "th",
                  {
                    class: [_vm.namespace("header-item")],
                    attrs: { scope: "col" }
                  },
                  [
                    _c("div", { class: _vm.namespace("table-header-flex") }, [
                      _c("div", { class: [_vm.namespace("flag-wrapper")] }, [
                        _c("span", {
                          class: [row.header.class, _vm.namespace("flag")]
                        })
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(row.header.value))])
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm._l(row.data, function(tdData, symbol) {
                  return _c(
                    "td",
                    {
                      key: symbol,
                      class: [_vm.namespace("td-item")].concat(tdData.class),
                      attrs: { "data-symbol": symbol }
                    },
                    [
                      _c("span", { class: _vm.namespace("td-item-span") }, [
                        _c("span", [_vm._v(_vm._s(tdData.value))])
                      ])
                    ]
                  )
                })
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _vm.forexDataModel.length
        ? _c(
            "div",
            { class: _vm.namespace("forex-legend") },
            _vm._l(_vm.ranges, function(item) {
              return _c("span", { key: item.id, class: _vm.namespace(item.id) })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "markets-ui-popover",
        {
          class: _vm.namespace("popover"),
          attrs: {
            skin: _vm.skin,
            width: _vm.popoverWidth,
            visible: _vm.popover.visible,
            "triggered-by": _vm.popover.triggeredId,
            position: _vm.popover.position
          }
        },
        [
          _c("div", { class: _vm.namespace("tip-top") }, [
            _c("span", { class: _vm.namespace("tip-currency-name") }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.popover.data.name) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                class: [
                  _vm.namespace("tip-currency-value"),
                  _vm.popover.data.chgClass
                ]
              },
              [_vm._v(_vm._s(_vm.popover.data.chg))]
            )
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.namespace("tip-middle") }, [
            _c("span", { class: _vm.namespace("tip-date-time") }, [
              _vm._v(
                _vm._s(_vm.labels.asof) +
                  " " +
                  _vm._s(_vm.popover.data.dateTime)
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { class: _vm.namespace("tip-bottom") }, [
            _c("span", { class: _vm.namespace("tip-rate-label") }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.labels.currentRate) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("span", { class: _vm.namespace("tip-rate-value") }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.popover.data.value) +
                  "\n            "
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }