const hasOwn = Object.prototype.hasOwnProperty;
const toString = Object.prototype.toString;
const class2type = (() => {
    const ret = {};
    'Boolean Number String export Function Array Date RegExp Object'
        .split(' ')
        .forEach(name => {
            ret[`[object ${name}]`] = name.toLowerCase();
        });
    return ret;
})();

function emptyComputedStyle() {
    return {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    };
}

function emptyBoundingClientRect() {
    return {
        height: 0,
        width: 0,
        top: 0,
        left: 0
    };
}

export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function getPaddings(element) {
    if (element instanceof HTMLElement) {
        const styles = getComputedStyle(element);
        return {
            top: parseFloat(styles.paddingTop, 10),
            right: parseFloat(styles.paddingRight, 10),
            bottom: parseFloat(styles.paddingBottom, 10),
            left: parseFloat(styles.paddingLeft, 10)
        };
    } else {
        return emptyComputedStyle();
    }
}

export function closest(src, cls, self) {
    const target = self ? src : (src || {}).parentNode;
    if (target === null) {
        return null;
    }
    if (target.classList && target.classList.contains(cls)) {
        return target;
    }
    return closest(target, cls);
}

export function getMargins(element) {
    if (element instanceof HTMLElement || element instanceof SVGElement) {
        const styles = getComputedStyle(element);
        return {
            top: parseFloat(styles.marginTop, 10),
            right: parseFloat(styles.marginRight, 10),
            bottom: parseFloat(styles.marginBottom, 10),
            left: parseFloat(styles.marginLeft, 10)
        };
    } else {
        return emptyComputedStyle();
    }
}

export function outerSizes(element, margin) {
    if (element instanceof HTMLElement || element instanceof SVGElement) {
        const boundRect = element.getBoundingClientRect();
        const scrollTop =
            document.documentElement.scrollTop ||
            window.pageYOffset ||
            document.body.scrollTop;
        const scrollLeft =
            document.documentElement.scrollLeft ||
            window.pageXOffset ||
            document.body.scrollLeft;
        let [marginTopBottom, marginLeftRight] = [0, 0];
        if (margin) {
            const margins = getMargins(element);
            marginTopBottom = margins.top + margins.bottom;
            marginLeftRight = margins.left + margins.right;
        }
        return {
            height: boundRect.height + (margin ? marginTopBottom : 0),
            width: boundRect.width + (margin ? marginLeftRight : 0),
            top: Math.floor(boundRect.top) + scrollTop,
            left: Math.floor(boundRect.left) + scrollLeft
        };
    } else {
        return emptyBoundingClientRect();
    }
}

export function innerSizes(element, withPadding) {
    if (element instanceof HTMLElement || element instanceof SVGElement) {
        const boundRect = element.getBoundingClientRect();
        const scrollTop =
            document.documentElement.scrollTop ||
            window.pageYOffset ||
            document.body.scrollTop;
        const scrollLeft =
            document.documentElement.scrollLeft ||
            window.pageXOffset ||
            document.body.scrollLeft;
        const paddings = getPaddings(element);
        const paddingTopBottom = paddings.top + paddings.bottom;
        const paddingLeftRight = paddings.left + paddings.right;
        return {
            height: withPadding
                ? boundRect.height
                : boundRect.height - paddingTopBottom,
            width: withPadding
                ? boundRect.width
                : boundRect.width - paddingLeftRight,
            top: Math.floor(boundRect.top) + scrollTop,
            left: Math.floor(boundRect.left) + scrollLeft
        };
    } else {
        return emptyBoundingClientRect();
    }
}

export function type(obj) {
    return obj === null
        ? String(obj)
        : class2type[toString.call(obj)] || 'object';
}

export function isFunction(obj) {
    return type(obj) === 'function';
}

export function isDate(obj) {
    return toString.call(obj) === '[object Date]';
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isWindow(obj) {
    return obj !== null && obj === obj.window;
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

export function isObject(obj) {
    const type = typeof obj;
    return obj !== null && (type === 'object' || type === 'function');
}

export function isPlainObject(obj) {
    if (
        toString.call(obj) !== '[object Object]' ||
        obj.nodeType ||
        isWindow(obj)
    ) {
        return false;
    }
    if (
        obj.constructor &&
        !hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')
    ) {
        return false;
    }
    return true;
}

export function isArray(obj) {
    return toString.call(obj) === '[object Array]';
}

export function inArray(array, value) {
    if (!array) {
        return -1;
    }
    const fn = d => {
        return d === value;
    };
    const accessor = isFunction(value) ? value : fn;
    for (let i = 0, l = array.length; i < l; i++) {
        if (accessor(array[i])) {
            return i;
        }
    }
    return -1;
}

export function find(array, value) {
    const index = inArray(array, value);
    if (index >= 0) {
        return array[index];
    } else {
        return undefined;
    }
}

/* eslint complexity: 0 */
/**
 * Deep clone Objects except for arrays are not merged
 * Use extend instead to also merge arrays recursively
 */
export function merge() {
    let options,
        name,
        src,
        copy,
        clone,
        target = arguments[0] || {},
        i = 1,
        deep = false;
    const length = arguments.length;
    // Handle a deep copy situation
    if (typeof target === 'boolean') {
        deep = target;

        // Skip the boolean and the target
        target = arguments[i] || {};
        i++;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== 'object' && !isFunction(target)) {
        target = {};
    }

    for (; i < length; i++) {
        // Only deal with non-null/undefined values
        if ((options = arguments[i]) !== null) {
            // Extend the base object
            for (name in options) {
                src = target[name];
                copy = options[name];

                // Prevent never-ending loop
                if (target === copy) {
                    continue;
                }
                // Recursive if we're merging plain objects or arrays
                if (deep && copy && isPlainObject(copy)) {
                    clone = src && isPlainObject(src) ? src : {};
                    // Never move original objects, clone them
                    target[name] = merge(deep, clone, copy);

                    // Don't bring in undefined values
                } else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }
    // Return the modified object
    return target;
}

/* eslint complexity: 0 */
export function extend() {
    let options,
        name,
        src,
        copy,
        copyIsArray,
        clone,
        target = arguments[0] || {},
        i = 1,
        deep = false;
    const length = arguments.length;
    // Handle a deep copy situation
    if (typeof target === 'boolean') {
        deep = target;

        // Skip the boolean and the target
        target = arguments[i] || {};
        i++;
    }

    // Handle case when target is a string or something (possible in deep copy)
    if (typeof target !== 'object' && !isFunction(target)) {
        target = {};
    }

    for (; i < length; i++) {
        // Only deal with non-null/undefined values
        if ((options = arguments[i]) !== null) {
            // Extend the base object
            for (name in options) {
                src = target[name];
                copy = options[name];

                // Prevent never-ending loop
                if (target === copy) {
                    continue;
                }

                // Recursive if we're merging plain objects or arrays
                if (
                    deep &&
                    copy &&
                    (isPlainObject(copy) || (copyIsArray = isArray(copy)))
                ) {
                    if (copyIsArray) {
                        copyIsArray = false;
                        clone = src && isArray(src) ? src : [];
                    } else {
                        clone = src && isPlainObject(src) ? src : {};
                    }
                    // Never move original objects, clone them
                    target[name] = extend(deep, clone, copy);

                    // Don't bring in undefined values
                } else if (copy !== undefined) {
                    target[name] = copy;
                }
            }
        }
    }

    // Return the modified object
    return target;
}

export function kebabCase(str) {
    return str.replace(/[A-Z\u00C0-\u00D6\u00D8-\u00DE]/g, function(match) {
        return `-${match.toLowerCase()}`;
    });
}

// same usage with lodash debounce
export function debounce(func, wait, options) {
    let lastArgs,
        lastThis,
        maxWait,
        result,
        timerId,
        lastCallTime,
        lastInvokeTime = 0,
        leading = false,
        maxing = false,
        trailing = true;
    if (!isFunction(func)) {
        throw new TypeError('Expected a function');
    }
    wait = wait || 0;

    if (isObject(options)) {
        leading = !!options.leading;
        maxing = 'maxWait' in options;
        maxWait = maxing ? Math.max(options.maxWait || 0, wait) : maxWait;
        trailing = 'trailing' in options ? !!options.trailing : trailing;
    }

    function invokeFunc(time) {
        const args = lastArgs;
        const thisArg = lastThis;
        lastArgs = lastThis = undefined;
        lastInvokeTime = time;
        result = func.apply(thisArg, args);
        return result;
    }

    function leadingEdge(time) {
        // Reset any `maxWait` timer.
        lastInvokeTime = time;
        // Start the timer for the trailing edge.
        timerId = setTimeout(timerExpired, wait);
        // Invoke the leading edge.
        return leading ? invokeFunc(time) : result;
    }

    function remainingWait(time) {
        const timeSinceLastCall = time - lastCallTime;
        const timeSinceLastInvoke = time - lastInvokeTime;
        const timeWaiting = wait - timeSinceLastCall;

        return maxing
            ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
            : timeWaiting;
    }

    function shouldInvoke(time) {
        const timeSinceLastCall = time - lastCallTime;
        const timeSinceLastInvoke = time - lastInvokeTime;

        // Either this is the first call, activity has stopped and we're at the
        // trailing edge, the system time has gone backwards and we're treating
        // it as the trailing edge, or we've hit the `maxWait` limit.
        return (
            lastCallTime === undefined ||
            timeSinceLastCall >= wait ||
            timeSinceLastCall < 0 ||
            (maxing && timeSinceLastInvoke >= maxWait)
        );
    }

    function timerExpired() {
        const time = Date.now();
        if (shouldInvoke(time)) {
            return trailingEdge(time);
        }
        // Restart the timer.
        timerId = setTimeout(timerExpired, remainingWait(time));
    }

    function trailingEdge(time) {
        timerId = undefined;

        // Only invoke if we have `lastArgs` which means `func` has been
        // debounced at least once.
        if (trailing && lastArgs) {
            return invokeFunc(time);
        }
        lastArgs = lastThis = undefined;
        return result;
    }

    function cancel() {
        if (timerId !== undefined) {
            clearTimeout(timerId);
        }
        lastInvokeTime = 0;
        lastArgs = lastCallTime = lastThis = timerId = undefined;
    }

    function flush() {
        return timerId === undefined ? result : trailingEdge(Date.now());
    }

    function debounced() {
        const time = Date.now();
        const isInvoking = shouldInvoke(time);

        lastArgs = arguments;
        lastThis = this;
        lastCallTime = time;

        if (isInvoking) {
            if (timerId === undefined) {
                return leadingEdge(lastCallTime);
            }
            if (maxing) {
                // Handle invocations in a tight loop.
                timerId = setTimeout(timerExpired, wait);
                return invokeFunc(lastCallTime);
            }
        }
        if (timerId === undefined) {
            timerId = setTimeout(timerExpired, wait);
        }
        return result;
    }
    debounced.cancel = cancel;
    debounced.flush = flush;
    return debounced;
}

export function throttle(func, wait, options) {
    let leading = true,
        trailing = true;

    if (!isFunction(func)) {
        throw new TypeError('Expected a function');
    }
    if (isObject(options)) {
        leading = 'leading' in options ? !!options.leading : leading;
        trailing = 'trailing' in options ? !!options.trailing : trailing;
    }
    return debounce(func, wait, {
        leading,
        maxWait: wait,
        trailing
    });
}
export function removeElement(element) {
    if (isFunction(element.remove)) {
        element.remove();
        return;
    }
    const _parentElement = element.parentNode;
    if (_parentElement) {
        _parentElement.removeChild(element);
    }
}
export function openHyperlink(params) {
    const form = document.createElement('form');
    form.style.display = 'none';
    form.action = params.url;
    form.method = params.method || 'post';
    form.target = params.target || 'formsubmit';
    document.body.appendChild(form);
    delete params.url;
    delete params.target;
    delete params.method;
    for (const key in params) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = params[key];
        if (key === 'data') {
            input.value = params[key].replace(/\u200e/g, '');
        }
        form.appendChild(input);
    }
    form.submit();
    removeElement(form);
}
export function isHidden(elem) {
    return elem.offsetWidth <= 0 && elem.offsetHeight <= 0;
}

export function prefixZero(num) {
    return num < 10 && `${num}`.length < 2 ? `0${num}` : `${num}`;
}

export function stringFormat() {
    if (arguments.length === 0) {
        return null;
    } else if (arguments.length === 1) {
        return arguments[0];
    }
    let str = arguments[0] || '';
    const obj = arguments[1] || {};
    for (const key in obj) {
        obj[key] = obj[key].replace(/\$/g, '$$$$');
        str = str.replace(new RegExp(`\\{${key}\\}`, 'g'), obj[key]);
    }
    return str;
}

export function cancelablePromise(promise) {
    let _canceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
        const cancelArg = {
            isCanceled: true
        };
        promise.then(val => (_canceled ? reject(cancelArg) : resolve(val)));
        promise.catch(error => (_canceled ? reject(cancelArg) : reject(error)));
    });
    return {
        promise: wrappedPromise,
        cancel() {
            _canceled = true;
        }
    };
}
export function lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getValueByPath(object, prop) {
    prop = prop || '';
    const paths = prop.split('.');
    let current = object;
    let result = null;
    for (let i = 0, j = paths.length; i < j; i++) {
        const path = paths[i];
        if (!current) {
            break;
        }

        if (i === j - 1) {
            result = current[path];
            break;
        }
        current = current[path];
    }
    return result;
}

export function orderBy(array, sortKey, reverse, sortMethod) {
    if (!sortKey) {
        return array;
    }
    const order = reverse ? 1 : -1;

    if (sortMethod) {
        sortMethod = function(a, b) {
            return sortMethod(a, b) ? order : -order;
        };
    } else {
        sortMethod = function(a, b) {
            a = isObject(a) ? getValueByPath(a, sortKey) : a;
            b = isObject(b) ? getValueByPath(b, sortKey) : b;
            const _order = a > b ? order : -order;
            return a === b ? 0 : _order;
        };
    }

    return [...array].sort(sortMethod);
}

export function hasOwnProperty(target, property) {
    return hasOwn.call(target, property);
}

export function computeTriggerId(el) {
    if (el instanceof HTMLElement || el instanceof SVGElement) {
        let id = el.getAttribute('id');
        if (!id) {
            id = `target-${guid()}`;
            el.setAttribute('id', id);
        }
        return id;
    }
    return null;
}

export function trim(str) {
    if (typeof str === 'string') {
        return str.replace(/(^\s*)|(\s*$)/g, '');
    }
    return null;
}
